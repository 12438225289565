// Download Map table data
import React, { useState } from 'react'
import { Button, Dialog } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import XLSX from 'xlsx';

export const drillsColumnSchema = [
    { field: 'id', headerName: 'row', width: 20, hide: true, },
    { field: 'drillJob', headerName: 'Drill Job', width: 100 },
    { field: 'endDate', headerName: 'End Date', width: 200 }, 
    { field: 'startDate', headerName: 'Start Date', width: 200 },
    { field: 'days', headerName: 'Days', width: 100 }, 
];

export const wellsColumnSchema = [
    { field: 'id', headerName: 'row', width: 20, hide: true, },
    { field: 'county', headerName: 'County', width: 100 }, 
    { field: 'horizontalLength', headerName: 'Horizontal Length', width: 150 },
    { field: 'measuredDept', headerName: 'Measured Depth', width: 100 }, 
    { field: 'operatorName', headerName: 'Operator Name', width: 300 },
    { field: 'permitDate', headerName: 'Permit Date', width: 250 },
    { field: 'state', headerName: 'State', width: 100 },
    { field: 'verticalDept', headerName: 'Total Vertical Depth', width: 100 },
    { field: 'wellBoreType', headerName: 'Wellbore Type', width: 100 },
    { field: 'api', headerName: 'API', width: 150 }
];

export const completionJobsColumnSchema = [
    { field: 'id', headerName: 'row', width: 20, hide: true, },
    { field: 'days', headerName: 'Days', width: 100 }, 
    { field: 'frackJob', headerName: 'Frac Job', width: 100 },
    { field: 'endDate', headerName: 'End Date', width: 200 }, 
    { field: 'startDate', headerName: 'Start Date', width: 200 }
];

const DownloadMapTableXLS = ({ datasets, userData, tabValue }) => {
    const [showDownloadXLSDialog, setShowDownloadXLSDialog] = useState(false)
    const [drillData, setDrillData] = useState([])
    const [wellsData, setWellsData] = useState([])
    const [completionData, setCompletionData] = useState([])

    const downloadXLSForTimeseries = () => {

        const filtered = datasets?.map(obj => {

            // get totals to add them later to keep column order (or use `header` param for columns order)
            const {
                wells,
                drills,
                completionJobs,
                ...rest
            } = obj;
        
            // flatten..
            drills.map(el => {
                drillsColumnSchema?.map(schema => rest[el[schema.headerName]] = el[schema.headerName] )
            });

            completionJobs.map(el => {
                completionJobsColumnSchema?.map(schema => rest[el[schema.headerName]] = el[schema.headerName] )
            });

            wells.map(el => {
                wellsColumnSchema?.map(schema => rest[el[schema.headerName]] = el[schema.headerName] )
            });

            return {
                ...rest,
                completionJobs,
                wells,
                drills
            };
        });

        const wellsWorkSheet = XLSX.utils.json_to_sheet([ ...filtered[0].wells ]);
        const drillsWorkSheet = XLSX.utils.json_to_sheet([ ...filtered[0].drills ]);
        const completionJobsWorkSheet = XLSX.utils.json_to_sheet([ ...filtered[0].completionJobs ]);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, wellsWorkSheet, 'Wells');
        XLSX.utils.book_append_sheet(workBook, drillsWorkSheet, 'Drills');
        XLSX.utils.book_append_sheet(workBook, completionJobsWorkSheet, 'Completion Jobs');
        
        XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' });
        XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' });
        XLSX.writeFile(workBook, `${userData.tabs[tabValue].name + '.xlsx'}`);
        setShowDownloadXLSDialog(false)
    }

    const getColumns = (datasets) => {

        let drillArr = [];
        let wellsArr = [];
        let completionJobArr = [];

        datasets.forEach((data, i) => {

            data.drills.forEach((element, i) => {
                drillArr.push({
                    id: i + 1,
                    days: element.Days,
                    drillJob: element['Drill Job'],
                    endDate: element['End Date'],
                    startDate: element['Start Date']
                });
            })

            data.wells.forEach((element, i) => {
                wellsArr.push({
                    id: i+1, 
                    county: element.County, 
                    horizontalLength : element['Horizontal Length'], 
                    measuredDept : element['Measured Depth'], 
                    operatorName: element['Operator Name'],
                    permitDate : element['Permit Date'],
                    state: element.State,
                    verticalDept: element['Total Vertical Depth'],
                    wellBoreType: element['Wellbore Type'],
                    api: element.api,
                });
            })

            data.completionJobs.forEach((element, i) => {
                completionJobArr.push({
                    id: i + 1,
                    days: element.Days,
                    frackJob: element['Frac Job'],
                    endDate: element['End Date'],
                    startDate: element['Start Date']
                });
            })
        })

        return { drillArr, wellsArr, completionJobArr }
    }

    useEffect(() => {
        let columnData = getColumns(datasets);
        setDrillData(columnData.drillArr);
        setWellsData(columnData.wellsArr);
        setCompletionData(columnData.completionJobArr);
    }, [datasets]);

    return (
        <>
            <Dialog
                open={showDownloadXLSDialog}
                onClose={() => setShowDownloadXLSDialog(false)}
                classes={{ root: 'MuiDialog-root downloadXLS__dialog' }}
            >
                <div className="downloadCsv__dialog">
                    <div className="downloadCsv__table">
                        <div className="downloadCsv__tables">
                            <div className="singleTable">
                                <div className="singleTable__heading">Wells</div>
                                <DataGrid columns={wellsColumnSchema} rows={wellsData} />
                            </div>
                            <div className="singleTable">
                                <div className="singleTable__heading">Drills</div>
                                <DataGrid columns={drillsColumnSchema} rows={drillData} />
                            </div>
                            <div className="singleTable">
                                <div className="singleTable__heading">Completion Jobs</div>
                                <DataGrid columns={completionJobsColumnSchema} rows={completionData} />
                            </div>
                        </div>
                        <div id="download">
                            <Button
                                style={{
                                color: '#fff',
                                backgroundColor: '#333',
                                border: '1px solid #ccc',
                                float: 'right',
                                fontSize: 12,
                                width: '10rem',
                                marginTop: 10
                            }}
                            onClick={downloadXLSForTimeseries}
                            >Download XLS</Button>
                        </div>
                    </div>
                    <br />
                </div>

            </Dialog>

            <Button
                style={{
                color: '#000',
                backgroundColor: '#f092dd',
                border: '1px solid #ccc',
                float: 'right',
                fontSize: 12,
                marginRight: 10,
                width:'100%'
                }}
                onClick={() => setShowDownloadXLSDialog(true)}
            >Download XLS</Button>
        </>
    )
}

export default DownloadMapTableXLS