import React, { useEffect, useRef, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line, Bar } from 'react-chartjs-2';
import { CircularProgress, Dialog, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Button } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import moment from 'moment'
import dayjs from 'dayjs'
import Map from '../Leaflet';

import { db } from '../../../App';

import { compare, getColumns } from '../utils'
import { allDates, leapYearDates } from '../allDates';
import SingleSlider from './SingleSlider';
import axios from 'axios';
import DownloadXLS from './DownloadXLS';
import { async } from '@firebase/util';

Chart.register(...registerables);

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  annotationPlugin
);

const prefixArr = (arr, string) => {
  let newArr = []

  for (let i = 0; i < arr.length; i++) {
    newArr.push(`${string}-${arr[i]}`)
  }
  return newArr
}

const getPointsFor7years = (points, years) => {
  const emptyYears = (days) => {
    let emptyYear = []
    for (let i = 0; i < days; i++) {
      emptyYear.push('*')
    }
    return emptyYear;
  }

  if (years === 0) {
    const arrToReturn = emptyYears(366).concat(emptyYears(365)).concat(emptyYears(365)).concat(emptyYears(365)).concat(emptyYears(366)).concat(emptyYears(365)).concat(emptyYears(365)).concat(points);
    return arrToReturn;
  }

  if (years === 1) {
    const arrToReturn = emptyYears(366).concat(emptyYears(365)).concat(emptyYears(365)).concat(emptyYears(365)).concat(emptyYears(366)).concat(emptyYears(365)).concat(points);
    return arrToReturn;
  }
  if (years === 2) {
    const arrToReturn = emptyYears(366).concat(emptyYears(365)).concat(emptyYears(365)).concat(emptyYears(365)).concat(emptyYears(366)).concat(points);
    return arrToReturn;
  }

  if (years === 3) {
    const arrToReturn = emptyYears(366).concat(emptyYears(365)).concat(emptyYears(365)).concat(emptyYears(365)).concat(points);
    return arrToReturn;
  }
}

const MainChart = ({ defaultDate, setDate, showTable, setShowTable, publicData, ownership, privateData, setEndMapDate, setMapStartDate, mapData, timeseriesChartData, userData, tabValue, setUserData, typeChartOrMap, token, setMapLayerDate, mapLayerDate, currentYear, setCurrentYear, selectedMonth, setSelectedMonth }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogForYaxis, setShowDialogForYaxis] = useState(false);
  const [primary, setPrimary] = useState({
    start: null,
    end: null
  });
  const [secondary, setSecondary] = useState({
    start: null,
    end: null
  });
  const [scaleRange, setScaleRange] = useState({
    start: null,
    end: null
  });
  const [scale, setScale] = useState('');
  const chartRef = useRef(null);
  const [startDate, setStartDate] = useState(
    dayjs(moment('2016-01-01').format('MM/DD/YYYY'))
  );
  const [endDate, setEndDate] = useState(
    dayjs(moment('2024-12-31').format('MM/DD/YYYY'))
  );
  const [rangeStart, setRangeStart] = useState(0)
  const [rangeEnd, setRangeEnd] = useState(2923)
  const [showErr, setShowErr] = useState(false)
  const [showPrimaryYAxis, setShowPrimaryYAxis] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [rowData, setRowData] = useState([])

  const [showSecondaryYAxis, setShowSecondaryYAxis] = useState(false)
  const leafLetRef = useRef(null);
  const loadShipsRef = useRef(null)

  useEffect(() => {
    if (userData.tabs[tabValue].startIndex) {
      setRangeStart(userData.tabs[tabValue].startIndex)
    }
    if (userData.tabs[tabValue].endIndex) {
      setRangeEnd(userData.tabs[tabValue].endIndex)
    }
  }, [userData])

  useEffect(() => {
    const shouldPrimaryYAxisBeVisible = timeseriesChartData.filter(item => item.axisType === 'primary').length > 0
    const shouldSecondaryYAxisBeVisible = timeseriesChartData.filter(item => item.axisType === 'secondary').length > 0
    setShowPrimaryYAxis(shouldPrimaryYAxisBeVisible)
    setShowSecondaryYAxis(shouldSecondaryYAxisBeVisible)
  }, [timeseriesChartData])

  useEffect(async () => {
    const userRef = doc(db, 'users', userData.uid);
    const docSnap = await getDoc(userRef);
    let chartData = docSnap.data();
    console.log(chartData.tabs[tabValue].yAxisFilter, 'chartData');
    setScale(chartData.tabs[tabValue].yAxisFilter.scale)
    setScaleRange({
      start: chartData.tabs[tabValue].yAxisFilter.start,
      end: chartData.tabs[tabValue].yAxisFilter.end
    })
    if (chartData.tabs[tabValue].yAxisFilter.scale === "Primary") {
      setPrimary({
        start: chartData.tabs[tabValue].yAxisFilter.start,
        end: chartData.tabs[tabValue].yAxisFilter.end
      })
    } else {
      setSecondary({
        start: chartData.tabs[tabValue].yAxisFilter.start,
        end: chartData.tabs[tabValue].yAxisFilter.end
      })
    }
  }, [])

  const getChartPointsData = (points, dataType, movingAvg) => {
    const arrAdjustedForMovingAvg = [];
    let movingAvgNumber = 1;

    if (movingAvg) {
      movingAvgNumber = movingAvg;
    }

    points?.forEach((data, i) => {
      if (i + 1 < movingAvgNumber) {
        arrAdjustedForMovingAvg.push("*");
      } else {
        arrAdjustedForMovingAvg.push(
          points.slice(i - (movingAvgNumber - 1), i + 1).reduce((partialSum, a) => partialSum + a, 0) /
          movingAvgNumber
        );
      }
    });
    return arrAdjustedForMovingAvg;
  };

  const labels = prefixArr(leapYearDates, '2016')
    .concat(prefixArr(allDates, '2017'))
    .concat(prefixArr(allDates, '2018'))
    .concat(prefixArr(allDates, '2019'))
    .concat(prefixArr(leapYearDates, '2020'))
    .concat(prefixArr(allDates, '2021'))
    .concat(prefixArr(allDates, '2022'))
    .concat(prefixArr(allDates, '2023'))
    .concat(prefixArr(leapYearDates, '2024'))

  const datasets = timeseriesChartData.map(({ timeseriesName, chartType, points, color, dataType, axisType, movingAvg }, i) => {
    console.log(chartType, ':chartType')
    return ({
      label: timeseriesName,
      type: `${chartType.toLowerCase()}`,
      // data: getPointsFor7years(points, 2),
      data: getChartPointsData(points, dataType, movingAvg).slice(rangeStart, rangeEnd),
      borderColor: color,
      backgroundColor: color,
      pointRadius: 2,
      yAxisID: axisType ? axisType : 'primary'
    })
  })

  const labelsWithIndex = labels.slice(rangeStart, rangeEnd)

  const data = {
    labels: labelsWithIndex,
    datasets
  };
  const isMobileScreen = window.innerWidth < 600;
  ChartJS.register(
    {
      id: 'uniqueid5', //typescript crashes without id
      afterDraw: function (chart, easing) {
        if (chart.tooltip._active && chart.tooltip._active.length) {
          const activePoint = chart.tooltip._active[0];
          const ctx = chart.ctx;
          const x = activePoint.element.x;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 2;
          ctx.strokeStyle = '#fff';
          ctx.stroke();
          ctx.restore();
        }
      }
    }
  );

  const scales = {
    x: {
      ticks: {
        maxTicksLimit: 12,
        font: {
          size: isMobileScreen ? 11 : 16,
          weight: isMobileScreen ? '' : 'bold'
        },
        callback: function (value, index) {
          const datestring = this.getLabelForValue(index);
          // console.log(datestring, 'datestring')
          // const firstPart = datestring.substr(0, 4)
          // const secondPart = datestring.substr(8)
          // return firstPart + '-' + secondPart;
          return datestring
        }
      },
    },
    y: {
      display: false,
    },
  }

  if (showPrimaryYAxis) {
    scales.primary = {
      type: 'linear',
      position: 'left',
      display: true,
      min: Number(primary.start) || null,
      max: Number(primary.end) || null,
    }
  }

  if (showSecondaryYAxis) {
    scales.secondary = {
      type: 'linear',
      display: true,
      position: 'right',
      max: Number(secondary.end) || null,
      min: Number(secondary.start) || null,
      ticks: {
        max: 1,
        min: 0
      }
    }
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    animationSteps: 1,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'index',
      intersect: false
    },
    plugins: {
      pan: {
        enabled: true,
        mode: 'x',
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          drag: {
            enabled: true
          },
          mode: 'y',
        },
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },

      legend: {
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: isMobileScreen ? 30 : 40,
          boxHeight: isMobileScreen ? 15 : 20,
          font: {
            size: isMobileScreen ? 11 : 14
          },

        }
      },
      title: {
        display: true,
        text: '',
        font: {
          size: isMobileScreen ? 14 : 18
        }
      },
    },
    scales,
    elements: {
      point: {
        radius: window.innerWidth < 600 ? 0 : 4,
      },
    },
  };

  const applyScaleToChart = async () => {
    if (moment(endDate.$d).isAfter(startDate.$d)) {
      setShowErr(false)
      const startIndex = labels.findIndex(e => e === moment(startDate.$d).format('YYYY-DD MMM'))
      const endIndex = labels.findIndex(e => e === moment(endDate.$d).format('YYYY-DD MMM'))
      var valueAtIndex1 = labels.at(startIndex);
      setRangeStart(startIndex)
      setMapStartDate(moment(startDate.$d).format('YYYY-MM-DD'))
      setEndMapDate(moment(endDate.$d).format('YYYY-MM-DD'))
      setRangeEnd(endIndex + 1)
      setShowDialog(false)
      const userRef = doc(db, 'users', userData.uid);
      const oldTabs = userData.tabs.filter(tab => tab.id !== tabValue)

      const updatedTabs = [...oldTabs, {
        ...userData.tabs[tabValue],
        startIndex,
        endIndex
      }]
      const updatedUserData = {
        ...userData,
        tabs: updatedTabs.sort(compare)
      }
      setUserData(updatedUserData)
      await updateDoc(userRef, updatedUserData);


    } else {
      setShowErr(true)
    }
  }

  const applyYAxisScaleToChart = () => {
    handleChangeScales();
    console.log(primary.start, primary.end, "start and end value")
    setShowDialogForYaxis(false);

  }

  const handleChange = (event) => {
    setScale(event.target.value);
  };


  const handleChangeScales = async () => {
    const userRef = doc(db, 'users', userData.uid);
    const oldTabs = userData.tabs.filter(tab => tab.id !== tabValue)

    let yAxisFilter

    if (scale === "Primary") {
      setPrimary({
        ...primary,
        ...scaleRange
      })
      yAxisFilter = {
        scale: scale,
        ...primary,
        ...scaleRange
      }
    } else {
      setSecondary({
        ...secondary,
        ...scaleRange
      })
      yAxisFilter = {
        scale: scale,
        ...secondary,
        ...scaleRange
      }
    }

    const updatedTabs = [...oldTabs, {
      ...userData.tabs[tabValue],
      yAxisFilter: yAxisFilter
    }]

    const updatedUserData = {
      ...userData,
      tabs: updatedTabs.sort(compare)
    }

    setUserData(updatedUserData)
    await updateDoc(userRef, updatedUserData);
  }

  const handleNewValue = (e) => {
    setScaleRange({ ...scaleRange, [e.target.name]: e.target.value });
  }

  useEffect(() => {
    setStartDate(userData.tabs[tabValue].startIndex ? (dayjs(moment(labels.at(userData.tabs[tabValue].startIndex)).format('MM/DD/YYYY'))) : dayjs(moment(labels.at('2016-01-01')).format('MM/DD/YYYY')))
    setEndDate(userData.tabs[tabValue].endIndex ? (dayjs(moment(labels.at(userData.tabs[tabValue].endIndex)).format('MM/DD/YYYY'))) : dayjs(moment(labels.at('2024-12-31')).format('MM/DD/YYYY')))
  }, [userData])



  const getTableData = async (id) => {
    setIsLoading(true)
    const tableDataRes = await axios.get('/map/table', {
      headers: { Authorization: 'Bearer ' + token },
      params: {
        wellId: id
      },
    });
    setRowData(Object.entries(tableDataRes?.data))
    setIsLoading(false)
  }

  const addTabForTable = async (id) => {
    setIsLoading(true)
    const userRef = doc(db, 'users', userData.uid);
    const docSnap = await getDoc(userRef);
    let chartData = docSnap.data();
    const availableTabs = chartData.tabs ? chartData.tabs : []
    let newTab = { name: id, id: availableTabs.length, tabType: 'table' }

    const updatedUserData = {
      ...chartData,
      tabs: [...availableTabs, newTab].sort(compare)
    }
    setUserData(updatedUserData)
    await updateDoc(userRef, updatedUserData);
    setIsLoading(false)

  }

  return (
    <>
      {isLoading ?
        <div className="loadingBox">
          <CircularProgress size={20} />
        </div> : ""}
      {!showTable && <div className={userData?.tabs[tabValue]?.tabType === "map" ? 'mainChart mainChart__map' : 'mainChart'}>
        <Dialog
          open={showDialog}
          onClose={() => setShowDialog(false)}
          classes={{ root: 'MuiDialog-root' }}
        >
          <div className="mainChart__customDateRange">
            <div className="mainChart__customDateRange--customrangeTitle">Select Custom Range</div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                label="Start Date"
                inputFormat="MM/DD/YYYY"
                value={startDate}
                onChange={setStartDate}
                renderInput={(params) => <TextField {...params} />}
                minDate={dayjs('2016-01-01')}
                maxDate={dayjs('2024-12-31')}
                views={['year', 'month', 'day']}
              />
              <br />
              <br />
              <DesktopDatePicker
                label="End date"
                inputFormat="MM/DD/YYYY"
                value={endDate}
                onChange={setEndDate}
                renderInput={(params) => <TextField {...params} />}
                minDate={dayjs('2016-01-01')}
                maxDate={dayjs('2024-12-31')}
                views={['year', 'month', 'day']}
              />
            </LocalizationProvider>
            {
              showErr && (<div style={{ color: 'red' }}>End Date needs to be after start date</div>)}
            <Button style={{
              color: '#fff',
              backgroundColor: '#23263C',
              border: '1px solid #23263C',
              marginTop: '1rem'
            }} variant="contained" onClick={applyScaleToChart}>Apply To Chart</Button>
          </div>
        </Dialog>

        {/* Dailog for selecting axis */}
        <Dialog
          open={showDialogForYaxis}
          onClose={() => setShowDialogForYaxis(false)}
          classes={{ root: 'MuiDialog-root' }}
        >
          <div className="mainChart__customDateRange">
            <div className="mainChart__customDateRange--customrangeTitle">Select Custom Range</div>
            <div>
              <div >
                <FormControl sx={{ mb: 2, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small">axis</InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={scale}
                    label="Age"
                    onChange={handleChange}
                  >
                    <MenuItem value={"Primary"}>Primary</MenuItem>
                    <br />
                    <MenuItem value={"Secondary"}>secondary</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Start Range"
                  id="outlined-size-small"
                  size="small"
                  name="start"
                  value={scaleRange.start}
                  onChange={(e) => {
                    handleNewValue(e);
                  }}
                />

              </div>
              <div>
                <TextField
                  label="End Range"
                  id="outlined-size-small"
                  size="small"
                  name="end"
                  value={scaleRange.end}
                  onChange={(e) => {
                    handleNewValue(e);
                  }}
                  sx={{ mt: 2 }}
                />
              </div>
            </div>
            {showErr && (<div style={{ color: 'red' }}>End Date needs to be after start date</div>)}
            <Button style={{
              color: '#fff',
              backgroundColor: '#23263C',
              border: '1px solid #23263C',
              marginTop: '1rem'
            }} variant="contained" onClick={applyYAxisScaleToChart}>Apply To Chart</Button>
          </div>
        </Dialog>

        {
          (userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) && (userData.tabs[tabValue].timeseriesArray.length > 0) &&
          <Button
            style={{
              color: '#fff',
              backgroundColor: '#333',
              border: '1px solid #fff',
              float: 'right',
              marginLeft: 10,
              fontSize: 12,
            }}
            onClick={() => setShowDialogForYaxis(true)}
          >Select Y-axis range
          </Button>
        }


        {
          (userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) && (userData.tabs[tabValue].timeseriesArray.length > 0) &&
          <Button
            style={{
              color: '#fff',
              backgroundColor: '#333',
              border: '1px solid #fff',
              float: 'right',
              marginLeft: 10,
              marginBottom: 10,
              fontSize: 12,
            }}
            onClick={() => setShowDialog(true)}
          >
            Select Date Range
          </Button>
        }

        {
          (userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) && (userData.tabs[tabValue].timeseriesArray.length > 0) &&
          <Button
            style={{
              color: '#fff',
              backgroundColor: '#333',
              border: '1px solid #fff',
              float: 'right',
              fontSize: 12,
            }}
            onClick={() => chartRef.current.resetZoom()}
          >
            Reset Scale
          </Button>
        }
        {
          userData?.tabs[tabValue]?.tabType === "map" && !showTable && (userData.tabs[tabValue].timeseriesArray.length > 0) &&
          <SingleSlider
            setDate={setDate}
            isDrawerOpen={true}
            loadShipsRef={loadShipsRef}
            isDrawerCollapsed={true}
            setMapLayerDate={setMapLayerDate}
            mapLayerDate={mapLayerDate}
            userData={userData}
            tabValue={tabValue}
            setUserData={setUserData}
            mapData={mapData}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            defaultDate={defaultDate}
          />
        }
        {
          (userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) &&
          datasets.length > 0 && <DownloadXLS labels={labelsWithIndex} datasets={datasets} />
        }
        {
          userData?.tabs[tabValue]?.tabType === "map" ?
            <Map
              addTabForTable={addTabForTable}
              setUserData={setUserData}
              ownership={ownership}
              publicData={publicData}
              privateData={privateData}
              resultMap={mapData}
              token={token}
              userData={userData}
              tabValue={tabValue}
            /> : <Bar ref={chartRef} options={options} data={data} />
        }
      </div>
      }

    </>
  )
}

export default MainChart