import moment from "moment";

export const ericTabs = [
  {
    id: 0,
    name: "L48",
    endIndex: 2738,
    timeseriesArray: [
      {
        selectedSubRegions: [],
        selectedRegion: [],
        timeseriesName: "Production",
        selectedOperator: null,
        color: "#cccccc",
        axisType: "primary",
        dataType: "Production",
        selectedCounty: "",
        movingAvg: 1,
        chartType: "Line",
      },
      {
        timeseriesName: "Forecast",
        selectedOperator: null,
        chartType: "Line",
        selectedSubRegions: [],
        axisType: "primary",
        selectedCounty: "",
        movingAvg: 1,
        selectedRegion: [],
        color: "#00ff4c",
        dataType: "Forecast",
      },
      {
        selectedRegion: [],
        selectedSubRegions: [],
        axisType: "secondary",
        color: "#24bdf0",
        timeseriesName: "Fracs",
        chartType: "Line",
        selectedOperator: null,
        movingAvg: 1,
        selectedCounty: "",
        dataType: "Fracking",
      },
    ],
    startIndex: 2192,
  },
  {
    startIndex: 2192,
    name: "PERMIAN",
    endIndex: 2738,
    timeseriesArray: [
      {
        selectedSubRegions: ["Permian-NM", "West - TX"],
        selectedRegion: ["Gulf", "West"],
        movingAvg: 1,
        axisType: "primary",
        dataType: "Production",
        timeseriesName: "Production",
        chartType: "Line",
        selectedCounty: "",
        selectedOperator: null,
        color: "#cccccc",
      },
      {
        selectedRegion: ["Gulf", "West"],
        chartType: "Line",
        color: "#00ff4c",
        selectedSubRegions: ["Permian-NM", "West - TX"],
        axisType: "primary",
        timeseriesName: "Forecast",
        selectedOperator: null,
        selectedCounty: "",
        movingAvg: 1,
        dataType: "Forecast",
      },
      {
        selectedRegion: ["Gulf", "West"],
        selectedCounty: "",
        color: "#fd8686",
        axisType: "secondary",
        movingAvg: 1,
        chartType: "Line",
        dataType: "Fracking",
        selectedOperator: null,
        timeseriesName: "Fracs",
        selectedSubRegions: ["West - TX", "Permian-NM"],
      },
    ],
    id: 1,
  },
  {
    id: 2,
    name: "Haynesville",
    endIndex: 2738,
    timeseriesArray: [
      {
        movingAvg: 1,
        dataType: "Fracking",
        timeseriesName: "Fracs",
        selectedCounty: "",
        selectedRegion: ["Gulf"],
        color: "#0baef4",
        selectedOperator: null,
        selectedSubRegions: ["Haynesville - LA", "Haynesville - TX"],
        axisType: "secondary",
        chartType: "Line",
      },
      {
        color: "#cccccc",
        dataType: "Production",
        axisType: "primary",
        selectedCounty: "",
        selectedSubRegions: ["Haynesville - LA", "Haynesville - TX"],
        selectedRegion: ["Gulf"],
        chartType: "Line",
        movingAvg: 1,
        timeseriesName: "Production",
        selectedOperator: null,
      },
      {
        axisType: "primary",
        selectedRegion: ["Gulf"],
        selectedOperator: null,
        color: "#05ff44",
        selectedCounty: "",
        chartType: "Line",
        timeseriesName: "Forecast",
        dataType: "Forecast",
        selectedSubRegions: ["Haynesville - LA", "Haynesville - TX"],
        movingAvg: 1,
      },
    ],
    startIndex: 2192,
  },
  {
    endIndex: 2738,
    id: 3,
    name: "EagleFord",
    timeseriesArray: [
      {
        axisType: "primary",
        selectedRegion: ["Gulf"],
        chartType: "Line",
        movingAvg: 1,
        timeseriesName: "Production",
        selectedSubRegions: ["South - TX"],
        selectedOperator: null,
        color: "#cccccc",
        selectedCounty: "",
        dataType: "Production",
      },
      {
        movingAvg: 1,
        color: "#00ff4c",
        selectedCounty: "",
        selectedOperator: null,
        chartType: "Line",
        dataType: "Forecast",
        axisType: "primary",
        selectedSubRegions: ["South - TX"],
        timeseriesName: "Forecast",
        selectedRegion: ["Gulf"],
      },
      {
        selectedRegion: ["Gulf"],
        axisType: "secondary",
        selectedCounty: "",
        chartType: "Line",
        dataType: "Fracking",
        movingAvg: 1,
        selectedOperator: null,
        color: "#00aaff",
        timeseriesName: "Fracs",
        selectedSubRegions: ["South - TX"],
      },
    ],
    startIndex: 2192,
  },
  {
    id: 4,
    endIndex: 2738,
    startIndex: 2192,
    name: "North East",
    timeseriesArray: [
      {
        selectedRegion: ["NorthEast"],
        timeseriesName: "Production",
        color: "#cccccc",
        selectedSubRegions: [],
        selectedOperator: null,
        dataType: "Production",
        chartType: "Line",
        selectedCounty: "",
        axisType: "primary",
        movingAvg: 1,
      },
      {
        movingAvg: 1,
        chartType: "Line",
        color: "#00ff4c",
        selectedSubRegions: [],
        timeseriesName: "Forecast",
        selectedCounty: "",
        axisType: "primary",
        selectedRegion: ["NorthEast"],
        dataType: "Forecast",
        selectedOperator: null,
      },
      {
        selectedSubRegions: [],
        chartType: "Line",
        axisType: "secondary",
        timeseriesName: "Fracs",
        selectedRegion: ["NorthEast"],
        movingAvg: 1,
        color: "#00ccff",
        dataType: "Fracking",
        selectedOperator: null,
        selectedCounty: "",
      },
    ],
  },
  {
    id: 5,
    endIndex: 2738,
    name: "L48 Fracs/Rigs",
    timeseriesArray: [
      {
        movingAvg: 1,
        chartType: "Line",
        axisType: "primary",
        selectedOperator: null,
        timeseriesName: "Fracs",
        color: "#cccccc",
        selectedCounty: null,
        selectedRegion: [],
        selectedSubRegions: [],
        dataType: "Fracking",
      },
      {
        chartType: "Line",
        movingAvg: 1,
        dataType: "Rigs",
        selectedCounty: null,
        timeseriesName: "Rigs",
        selectedOperator: null,
        selectedRegion: [],
        selectedSubRegions: [],
        axisType: "secondary",
        color: "#e6f764",
      },
    ],
    startIndex: 1917,
  },
  {
    timeseriesArray: [
      {
        selectedOperator: "EXXON MOBIL",
        dataType: "Fracking",
        selectedRegion: [],
        movingAvg: 1,
        axisType: "primary",
        chartType: "Line",
        selectedSubRegions: [],
        color: "#cccccc",
        selectedCounty: null,
        timeseriesName: "Fracs",
      },
      {
        selectedRegion: [],
        dataType: "Rigs",
        selectedOperator: "EXXON MOBIL",
        chartType: "Line",
        axisType: "primary",
        color: "#e6f764",
        movingAvg: 1,
        timeseriesName: "Rigs",
        selectedSubRegions: [],
        selectedCounty: null,
      },
      {
        selectedOperator: "EXXON MOBIL",
        selectedSubRegions: [],
        movingAvg: 1,
        selectedCounty: null,
        axisType: "primary",
        timeseriesName: "Completions",
        selectedRegion: [],
        dataType: "Monthly Completions",
        chartType: "Bar",
        color: "#ff7070",
      },
      {
        selectedSubRegions: [],
        selectedCounty: null,
        timeseriesName: "DUCs",
        selectedOperator: "EXXON MOBIL",
        dataType: "DUCs",
        axisType: "secondary",
        selectedRegion: [],
        movingAvg: 1,
        chartType: "Line",
        color: "#bdc1ff",
      },
    ],
    startIndex: 2192,
    name: "EXXON",
    id: 6,
    endIndex: 2738,
  },
  {
    name: "Pioneer",
    id: 7,
    endIndex: 2738,
    timeseriesArray: [
      {
        selectedSubRegions: [],
        selectedOperator: "PIONEER NATURAL RESOURCES",
        color: "#cccccc",
        timeseriesName: "Fracs",
        axisType: "primary",
        selectedRegion: [],
        dataType: "Fracking",
        movingAvg: 1,
        selectedCounty: null,
        chartType: "Line",
      },
      {
        dataType: "Rigs",
        chartType: "Line",
        selectedSubRegions: [],
        axisType: "primary",
        color: "#e6f764",
        selectedCounty: null,
        timeseriesName: "Rigs",
        selectedOperator: "PIONEER NATURAL RESOURCES",
        selectedRegion: [],
        movingAvg: 1,
      },
      {
        color: "#b7b6fb",
        selectedSubRegions: [],
        movingAvg: 1,
        axisType: "secondary",
        dataType: "DUCs",
        selectedCounty: null,
        timeseriesName: "DUCs",
        selectedRegion: [],
        selectedOperator: "PIONEER NATURAL RESOURCES",
        chartType: "Line",
      },
    ],
    startIndex: 1917,
  },
  {
    timeseriesArray: [
      {
        color: "#cccccc",
        dataType: "Rigs",
        timeseriesName: "Rigs",
        chartType: "Line",
        movingAvg: 1,
        selectedRegion: ["West"],
        selectedOperator: null,
        axisType: "primary",
        selectedCounty: "",
        selectedSubRegions: ["NewMexico", "Permian-NM"],
      },
      {
        timeseriesName: "DUCS",
        selectedCounty: "",
        selectedSubRegions: ["NewMexico", "Permian-NM"],
        chartType: "Line",
        axisType: "secondary",
        selectedOperator: null,
        selectedRegion: ["West"],
        movingAvg: 1,
        dataType: "DUCs",
        color: "#f9aeae",
      },
      {
        color: "#aed6f9",
        chartType: "Line",
        movingAvg: 1,
        selectedRegion: ["West"],
        dataType: "Fracking",
        axisType: "primary",
        selectedSubRegions: ["NewMexico", "Permian-NM"],
        timeseriesName: "Fracs",
        selectedOperator: null,
        selectedCounty: "",
      },
    ],
    name: "NM Rigs",
    endIndex: 2738,
    startIndex: 2282,
    id: 8,
  },
  {
    endIndex: 2738,
    name: "Permian-NM",
    startIndex: 2192,
    id: 9,
    timeseriesArray: [
      {
        timeseriesName: "Production",
        chartType: "Line",
        selectedRegion: ["Gulf", "West"],
        movingAvg: 1,
        dataType: "Production",
        axisType: "primary",
        selectedOperator: null,
        selectedSubRegions: ["Permian-NM"],
        color: "#cccccc",
        selectedCounty: "",
      },
      {
        dataType: "Forecast",
        movingAvg: 1,
        selectedSubRegions: ["Permian-NM"],
        selectedRegion: ["Gulf", "West"],
        axisType: "primary",
        selectedCounty: "",
        timeseriesName: "Forecast",
        color: "#00ff4c",
        selectedOperator: null,
        chartType: "Line",
      },
      {
        selectedOperator: null,
        axisType: "secondary",
        selectedRegion: ["Gulf", "West"],
        dataType: "Fracking",
        selectedCounty: "",
        chartType: "Line",
        movingAvg: 1,
        color: "#fd8686",
        selectedSubRegions: ["Permian-NM"],
        timeseriesName: "Fracs",
      },
    ],
  },
  {
    id: 10,
    endIndex: 2738,
    name: "Haynesville - LA",
    timeseriesArray: [
      {
        selectedSubRegions: ["Haynesville - LA"],
        movingAvg: 1,
        selectedRegion: ["Gulf"],
        dataType: "Fracking",
        axisType: "secondary",
        selectedCounty: "",
        selectedOperator: null,
        chartType: "Line",
        timeseriesName: "Fracs",
        color: "#0baef4",
      },
      {
        chartType: "Line",
        selectedRegion: ["Gulf"],
        selectedCounty: "",
        axisType: "primary",
        selectedSubRegions: ["Haynesville - LA"],
        timeseriesName: "Production",
        color: "#cccccc",
        movingAvg: 1,
        selectedOperator: null,
        dataType: "Production",
      },
      {
        axisType: "primary",
        selectedOperator: null,
        selectedSubRegions: ["Haynesville - LA"],
        selectedRegion: ["Gulf"],
        selectedCounty: "",
        chartType: "Line",
        color: "#05ff44",
        dataType: "Forecast",
        movingAvg: 1,
        timeseriesName: "Forecast",
      },
    ],
    startIndex: 2343,
  },
  {
    endIndex: 2738,
    name: "Haynesville - TX",
    startIndex: 2373,
    id: 11,
    timeseriesArray: [
      {
        selectedSubRegions: ["Haynesville - TX"],
        dataType: "Fracking",
        selectedCounty: "",
        chartType: "Line",
        selectedOperator: null,
        timeseriesName: "Fracs",
        movingAvg: 1,
        color: "#0baef4",
        axisType: "secondary",
        selectedRegion: ["Gulf"],
      },
      {
        selectedRegion: ["Gulf"],
        chartType: "Line",
        selectedOperator: null,
        axisType: "primary",
        timeseriesName: "Production",
        movingAvg: 1,
        dataType: "Production",
        selectedSubRegions: ["Haynesville - TX"],
        selectedCounty: "",
        color: "#cccccc",
      },
      {
        dataType: "Forecast",
        movingAvg: 1,
        axisType: "primary",
        color: "#05ff44",
        selectedOperator: null,
        selectedCounty: "",
        timeseriesName: "Forecast",
        chartType: "Line",
        selectedRegion: ["Gulf"],
        selectedSubRegions: ["Haynesville - TX"],
      },
    ],
  },
  {
    mapCenter: '{"lat":27.098770672975643,"lng":-106.91939009353398}',
    name: "Map",
    id: 12,
    tabType: "map",
    mapZoom: 4,
    timeseriesArray: [
      {
        selectedOperator: null,
        selectedSubRegions: [],
        timeseriesName: "Rigs",
        chartType: "Line",
        selectedRegion: [],
        dataType: "drilling",
        axisType: "primary",
        movingAvg: 1,
        selectedCounty: null,
        color: "#1d1b1b",
      },
      {
        timeseriesName: "Fracs",
        selectedRegion: [],
        axisType: "primary",
        dataType: "Fracking",
        selectedSubRegions: [],
        selectedCounty: null,
        movingAvg: 1,
        selectedOperator: null,
        color: "#f33535",
        chartType: "Line",
      },
    ],
  },
  {
    endIndex: 2738,
    name: "ND",
    id: 13,
    startIndex: 2192,
    tabType: "chart",
    timeseriesArray: [
      {
        dataType: "Production",
        movingAvg: 1,
        axisType: "primary",
        selectedCounty: "",
        color: "#cccccc",
        selectedRegion: ["MidWest"],
        chartType: "Line",
        timeseriesName: "Production",
        selectedOperator: null,
        selectedSubRegions: ["North Dakota"],
      },
      {
        dataType: "Forecast",
        selectedSubRegions: ["North Dakota"],
        movingAvg: 1,
        axisType: "primary",
        chartType: "Line",
        selectedOperator: null,
        selectedCounty: "",
        color: "#00ff4c",
        timeseriesName: "Forecast",
        selectedRegion: ["MidWest"],
      },
      {
        selectedRegion: ["MidWest"],
        color: "#708fff",
        dataType: "Fracking",
        movingAvg: 1,
        selectedOperator: null,
        timeseriesName: "Fracs",
        selectedSubRegions: ["North Dakota"],
        axisType: "secondary",
        chartType: "Line",
        selectedCounty: "",
      },
    ],
  },
  {
    tabType: "chart",
    startIndex: 2192,
    id: 14,
    timeseriesArray: [
      {
        selectedOperator: null,
        dataType: "Fracking",
        selectedSubRegions: ["West - TX"],
        selectedCounty: "",
        color: "#cccccc",
        chartType: "Line",
        movingAvg: 1,
        selectedRegion: ["Gulf"],
        axisType: "primary",
        timeseriesName: "Production",
      },
      {
        dataType: "Fracked Feet Per Day",
        selectedCounty: "",
        selectedOperator: null,
        color: "#fd8686",
        movingAvg: 1,
        selectedSubRegions: ["West - TX"],
        selectedRegion: ["Gulf"],
        timeseriesName: "FFD",
        chartType: "Line",
        axisType: "secondary",
      },
    ],
    endIndex: 2738,
    name: "PERMIAN FFD",
  },
];

export const dataCenterEricTabs = [
  {
    endIndex: 2615,
    viewMode: "Chart",
    chartType: "timeSeries",
    timeseriesArray: [
      {
        selectedSubRegions: ["Permian-NM", "West - TX"],
        selectedCounty: "",
        dataType: "Production",
        movingAvg: 1,
        color: "#cccccc",
        timeseriesName: "Production",
        selectedRegion: ["Gulf", "West"],
        chartType: "Line",
        axisType: "primary",
        selectedOperator: null,
      },
      {
        selectedOperator: null,
        movingAvg: 1,
        chartType: "Line",
        selectedCounty: "",
        color: "#00ff4c",
        selectedRegion: ["Gulf", "West"],
        selectedSubRegions: ["Permian-NM", "West - TX"],
        dataType: "Natural Gas Forecast",
        timeseriesName: "Forecast",
        axisType: "primary",
      },
      {
        color: "#00ccff",
        chartType: "Line",
        axisType: "secondary",
        movingAvg: 45,
        selectedRegion: ["Gulf", "West"],
        selectedCounty: "",
        selectedSubRegions: ["Permian-NM", "West - TX"],
        dataType: "Fracking",
        timeseriesName: "Fracs 45",
        selectedOperator: null,
      },
    ],
    id: 0,
    name: "PERMIAN",
    startIndex: 1827,
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
    lastOpenedAt: moment().unix(),
  },
  {
    name: "Haynesville",
    viewMode: "Chart",
    chartType: "timeSeries",
    timeseriesArray: [
      {
        selectedRegion: ["Gulf"],
        selectedOperator: null,
        color: "#0baef4",
        selectedSubRegions: ["Haynesville - LA", "Haynesville - TX"],
        timeseriesName: "Fracs 45",
        selectedCounty: "",
        dataType: "Fracking",
        axisType: "secondary",
        movingAvg: 45,
        chartType: "Line",
      },
      {
        color: "#cccccc",
        selectedCounty: "",
        dataType: "Production",
        axisType: "primary",
        selectedRegion: ["Gulf"],
        selectedSubRegions: ["Haynesville - LA", "Haynesville - TX"],
        selectedOperator: null,
        chartType: "Line",
        timeseriesName: "Production",
        movingAvg: 1,
      },
      {
        timeseriesName: "Forecast",
        axisType: "primary",
        selectedSubRegions: ["Haynesville - LA", "Haynesville - TX"],
        selectedOperator: null,
        chartType: "Line",
        selectedCounty: "",
        selectedRegion: ["Gulf"],
        color: "#05ff44",
        dataType: "Natural Gas Forecast",
        movingAvg: 1,
      },
    ],
    startIndex: 1827,
    endIndex: 2615,
    id: 1,
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
    lastOpenedAt: moment().unix(),
  },
  {
    name: "EagleFord",
    id: 2,
    startIndex: 1827,
    endIndex: 2615,
    viewMode: "Chart",
    chartType: "timeSeries",
    timeseriesArray: [
      {
        movingAvg: 1,
        timeseriesName: "Production",
        selectedSubRegions: ["South - TX"],
        selectedRegion: ["Gulf"],
        color: "#cccccc",
        chartType: "Line",
        selectedOperator: null,
        dataType: "Production",
        axisType: "primary",
        selectedCounty: "",
      },
      {
        dataType: "Natural Gas Forecast",
        selectedSubRegions: ["South - TX"],
        selectedOperator: null,
        selectedCounty: "",
        color: "#00ff4c",
        timeseriesName: "Forecast",
        selectedRegion: ["Gulf"],
        movingAvg: 1,
        axisType: "primary",
        chartType: "Line",
      },
      {
        selectedOperator: null,
        chartType: "Line",
        selectedSubRegions: ["South - TX"],
        timeseriesName: "Fracs 45",
        axisType: "secondary",
        selectedCounty: "",
        color: "#00aaff",
        selectedRegion: ["Gulf"],
        dataType: "Fracking",
        movingAvg: 45,
      },
    ],
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
    lastOpenedAt: moment().unix(),
  },
  {
    viewMode: "Chart",
    chartType: "timeSeries",
    timeseriesArray: [
      {
        timeseriesName: "Production",
        selectedRegion: [],
        selectedSubRegions: [],
        dataType: "Production",
        chartType: "Line",
        color: "#cccccc",
        selectedCounty: "",
        axisType: "primary",
        movingAvg: 1,
        selectedOperator: null,
      },
      {
        selectedRegion: [],
        axisType: "primary",
        selectedSubRegions: [],
        selectedOperator: null,
        chartType: "Line",
        timeseriesName: "Forecast",
        selectedCounty: "",
        dataType: "Natural Gas Forecast",
        color: "#00ff4c",
        movingAvg: 1,
      },
      {
        selectedRegion: [],
        selectedSubRegions: [],
        movingAvg: 45,
        axisType: "secondary",
        color: "#24bdf0",
        selectedOperator: null,
        dataType: "Fracking",
        chartType: "Line",
        selectedCounty: "",
        timeseriesName: "Fracs 45",
      },
    ],
    endIndex: 2615,
    startIndex: 2192,
    id: 3,
    name: "L48",
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
    lastOpenedAt: moment().unix(),
  },
  {
    name: "North East",
    endIndex: 2615,
    id: 4,
    startIndex: 2192,
    viewMode: "Chart",
    chartType: "timeSeries",
    timeseriesArray: [
      {
        dataType: "Production",
        selectedSubRegions: [],
        movingAvg: 1,
        chartType: "Line",
        axisType: "primary",
        selectedOperator: null,
        timeseriesName: "Production",
        selectedRegion: ["NorthEast"],
        selectedCounty: "",
        color: "#cccccc",
      },
      {
        movingAvg: 1,
        selectedCounty: "",
        selectedRegion: ["NorthEast"],
        dataType: "Natural Gas Forecast",
        axisType: "primary",
        chartType: "Line",
        color: "#00ff4c",
        timeseriesName: "Forecast",
        selectedSubRegions: [],
        selectedOperator: null,
      },
      {
        selectedSubRegions: [],
        selectedCounty: "",
        color: "#00ccff",
        chartType: "Line",
        timeseriesName: "Fracs 45",
        axisType: "secondary",
        movingAvg: 45,
        selectedOperator: null,
        dataType: "Fracking",
        selectedRegion: ["NorthEast"],
      },
    ],
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
    lastOpenedAt: moment().unix(),
  },
  {
    endIndex: 2556,
    id: 5,
    name: "L48 Fracs/Rigs",
    viewMode: "Chart",
    chartType: "timeSeries",

    timeseriesArray: [
      {
        chartType: "Line",
        movingAvg: 1,
        selectedRegion: [],
        timeseriesName: "Fracs",
        axisType: "primary",
        selectedSubRegions: [],
        color: "#cccccc",
        selectedOperator: null,
        dataType: "Fracking",
        selectedCounty: null,
      },
      {
        chartType: "Line",
        timeseriesName: "Rigs",
        selectedOperator: null,
        selectedCounty: null,
        movingAvg: 1,
        color: "#e6f764",
        axisType: "secondary",
        selectedSubRegions: [],
        selectedRegion: [],
        dataType: "Rigs",
      },
    ],
    startIndex: 1917,
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
    lastOpenedAt: moment().unix(),
  },
  {
    id: 6,
    endIndex: 2556,
    startIndex: 1917,
    viewMode: "Chart",
    chartType: "timeSeries",
    timeseriesArray: [
      {
        axisType: "primary",
        dataType: "Fracking",
        selectedCounty: null,
        color: "#cccccc",
        timeseriesName: "Fracs",
        selectedOperator: "EXXON MOBIL",
        movingAvg: 1,
        selectedRegion: [],
        chartType: "Line",
        selectedSubRegions: [],
      },
      {
        selectedOperator: "EXXON MOBIL",
        chartType: "Line",
        color: "#e6f764",
        selectedRegion: [],
        selectedCounty: null,
        selectedSubRegions: [],
        dataType: "Rigs",
        axisType: "primary",
        movingAvg: 1,
        timeseriesName: "Rigs",
      },
      {
        color: "#ff7070",
        selectedRegion: [],
        selectedCounty: null,
        dataType: "Monthly Completions",
        chartType: "Bar",
        selectedOperator: "EXXON MOBIL",
        selectedSubRegions: [],
        movingAvg: 1,
        timeseriesName: "Completions",
        axisType: "primary",
      },
      {
        selectedSubRegions: [],
        selectedRegion: [],
        color: "#bdc1ff",
        chartType: "Line",
        dataType: "DUCs",
        movingAvg: 1,
        timeseriesName: "DUCs",
        selectedCounty: null,
        selectedOperator: "EXXON MOBIL",
        axisType: "secondary",
      },
    ],
    name: "EXXON",
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
    lastOpenedAt: moment().unix(),
  },
  {
    id: 7,
    viewMode: "Chart",
    chartType: "timeSeries",
    timeseriesArray: [
      {
        movingAvg: 1,
        chartType: "Line",
        dataType: "Fracking",
        selectedOperator: "PIONEER NATURAL RESOURCES",
        color: "#cccccc",
        selectedSubRegions: [],
        timeseriesName: "Fracs",
        axisType: "primary",
        selectedCounty: null,
        selectedRegion: [],
      },
      {
        movingAvg: 1,
        timeseriesName: "Rigs",
        selectedOperator: "PIONEER NATURAL RESOURCES",
        dataType: "Rigs",
        selectedCounty: null,
        selectedRegion: [],
        axisType: "primary",
        chartType: "Line",
        color: "#e6f764",
        selectedSubRegions: [],
      },
      {
        selectedCounty: null,
        selectedSubRegions: [],
        axisType: "secondary",
        chartType: "Line",
        movingAvg: 1,
        selectedOperator: "PIONEER NATURAL RESOURCES",
        selectedRegion: [],
        dataType: "DUCs",
        color: "#b7b6fb",
        timeseriesName: "DUCs",
      },
    ],
    endIndex: 2556,
    startIndex: 1917,
    name: "Pioneer",
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
    lastOpenedAt: moment().unix(),
  },
  {
    startIndex: 2282,
    name: "NM Rigs",
    endIndex: 2556,
    viewMode: "Chart",
    chartType: "timeSeries",
    timeseriesArray: [
      {
        timeseriesName: "Rigs",
        selectedCounty: "",
        dataType: "Rigs",
        axisType: "primary",
        chartType: "Line",
        movingAvg: 1,
        selectedOperator: null,
        selectedRegion: ["West"],
        selectedSubRegions: ["NewMexico", "Permian-NM"],
        color: "#cccccc",
      },
      {
        color: "#f9aeae",
        movingAvg: 1,
        selectedOperator: null,
        chartType: "Line",
        timeseriesName: "DUCS",
        axisType: "secondary",
        selectedSubRegions: ["NewMexico", "Permian-NM"],
        selectedRegion: ["West"],
        selectedCounty: "",
        dataType: "DUCs",
      },
      {
        selectedOperator: null,
        dataType: "Fracking",
        selectedSubRegions: ["NewMexico", "Permian-NM"],
        selectedRegion: ["West"],
        selectedCounty: "",
        movingAvg: 1,
        timeseriesName: "Fracs",
        color: "#aed6f9",
        chartType: "Line",
        axisType: "primary",
      },
    ],
    id: 8,
    createdAt: moment().unix(),
    updatedAt: moment().unix(),
    lastOpenedAt: moment().unix(),
  },
];
export const hyperionCustomerRoles = ["co-worker","hyperion_customer","temporary_hyperion_user", "vulcan_and_hyperion_customer"];