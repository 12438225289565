import React, { useState, useEffect } from 'react'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Dialog, Button } from '@material-ui/core';
import { collection, getDocs, doc, updateDoc, onSnapshot, getDoc } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';

import { db } from '../../../App';
import './DataArea.scss';
import ChartAndMapArea from './ChartAndMapArea';
import axios from 'axios';
import { getLineChartData, compare, getLineChartDataPoints } from '../utils'

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import moment from 'moment';
// import Button from '@mui/material/Button';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Stack from '@mui/material/Stack';
import { useRef } from 'react';
import { padding } from '@mui/system';
import { async } from '@firebase/util';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


const DataArea = ({ userData, setUserData, token, barData, isLoading, clickedRegion, selectedRegion, setSelectedRegion, setClickedRegion, setselectedSubRegions,
  setSelectedOperator, operators, selectedOperator, selectedCounty, setSelectedCounty, counties, subRegions, selectedSubRegions, isMapClicked }) => {
  const [tabValue, setTabValue] = useState(0);
  const [showDialog, setShowDialog] = useState(false)
  const [showCloneDialog, setShowCloneDialog] = useState(false)
  const [newTabName, setNewTabName] = useState('')
  const [timeseriesChartData, setTimeseriesChartData] = useState([])
  const [isLoadingNewTab, setIsLoadingNewTab] = useState(false)
  const [isMapReloading, setIsMapReloading] = useState(false)
  const [typeChartOrMap, setTypeChartOrMap] = useState("chart");
  const [mapData, setMapData] = useState([]);
  const [mapStartDate, setMapStartDate] = useState('2016-01-01');
  const [endMapDate, setEndMapDate] = useState('2024-12-31')
  const [mapLayerDate, setMapLayerDate] = useState(null)
  const [date, setDate] = useState(null)
  const [currentYear, setCurrentYear] = useState();
  const [selectedMonth, setSelectedMonth] = useState();
  const [defaultDate, setDefaultDate] = useState({
    day: '',
    month: '',
    year: ''
  });

  const [open, setOpen] = useState(false);
  const anchorRef = useRef();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const addNewTab = async () => {
    setIsLoadingNewTab(true)
    const userRef = doc(db, 'users', userData.uid);
    const docSnap = await getDoc(userRef);
    let chartData = docSnap.data();
    const availableTabs = chartData.tabs ? chartData.tabs : []

    let newTab = { name: newTabName, timeseriesArray: [], id: availableTabs.length, tabType: typeChartOrMap }

    if (showCloneDialog) {
      newTab = { ...chartData.tabs[tabValue], name: newTabName, id: availableTabs.length, tabType: userData?.tabs[tabValue]?.tabType || typeChartOrMap }
    }

    const updatedUserData = {
      ...chartData,
      tabs: [...availableTabs, newTab].sort(compare)
    }

    setUserData(updatedUserData)
    await updateDoc(userRef, updatedUserData);
    setIsLoadingNewTab(false)
    setShowCloneDialog(false)
    setTabValue(availableTabs.length);
    setNewTabName('')
    setShowDialog(false)
    // window.location.reload();
  }

  // useEffect(() => {
  //   getProductionData()
  // },[])


  // useEffect(() => {
  //   const colRef = collection(db, "users")
  //   // real time update
  //   onSnapshot(colRef, (snapshot) => {
  //     snapshot.docs.forEach((doc) => {
  //       let data = doc.data();
  //       if(data.email === userData.email){
  //         setUserData( doc.data())
  //       }
  //       })
  //   })
  // }, [])

  const getProductionData = async () => {
    const res = await axios.get('/geoml/getProductionData', {
      headers: { Authorization: 'Bearer ' + token },
    });
    console.log(res, 'production data')
  }

  useEffect(() => {
    if (userData.tabs && userData.tabs.length > 0 && userData.tabs[tabValue].timeseriesArray && userData.tabs[tabValue].timeseriesArray.length > 0) {
      renderTimeseriesCharts()
    }
  }, [userData, tabValue, mapLayerDate])

  // useEffect(()=>{
  //   setDate(null)
  // },[tabValue])

  const renderTimeseriesCharts = async () => {
    console.log('fetching data')
    setIsMapReloading(true)
    if (userData.tabs[tabValue].tabType === 'chart' || !userData.tabs[tabValue].tabType) {
      const res = await axios.get('/geoml/getTimeseriesData', {
        headers: { Authorization: 'Bearer ' + token },
        params: {
          timeseriesArray: userData.tabs[tabValue].timeseriesArray
        },
      });
      console.log(res.data, 'res from timeseries')

      const timeseriesWithDataPoints = res.data.finalResults;
      const chartDataForTimeseries = []

      timeseriesWithDataPoints.forEach(timeseries => {
        const resultCount = timeseries.resultCount.recordset.reverse()
        const dataType = timeseries.dataType

        if (dataType === 'Fracking' || dataType === 'Rigs' || dataType === 'Fracked Feet Per Day') {
          const chartDataPoints = getLineChartDataPoints(resultCount, true);
          chartDataForTimeseries.push({ points: chartDataPoints, ...timeseries })
        }

        if (dataType === 'DUCs' || dataType === 'Production' || dataType === 'Oil Forecast' || dataType === 'Oil Production') {
          const chartDataPoints = getLineChartDataPoints(resultCount, false);
          chartDataForTimeseries.push({ points: chartDataPoints, ...timeseries })
        }

        if (dataType === 'Monthly Completions') {
          const chartDataPoints = getLineChartDataPoints(resultCount.reverse(), false);
          chartDataForTimeseries.push({ points: chartDataPoints, ...timeseries })
        }

        if (dataType === 'Forecast') {
          resultCount?.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
          );
          const chartDataPoints = getLineChartDataPoints(resultCount, false);
          chartDataForTimeseries.push({ points: chartDataPoints, ...timeseries })
        }
      })
      setIsMapReloading(false)
      setTimeseriesChartData(chartDataForTimeseries)
    } else if (userData.tabs[tabValue].tabType === 'map') {
      if (userData) {
        setIsMapReloading(true)
        const docRef = doc(db, "users", userData.uid);
        const docSnap = await getDoc(docRef);
        const chartData = docSnap.data();
        let selectedDate = chartData.tabs[tabValue].selectedDateForMap ? chartData.tabs[tabValue].selectedDateForMap : "";
        console.log(selectedDate, "selectedDate")
        const mapDataRes = await axios.get('/map', {
          headers: { Authorization: 'Bearer ' + token },
          params: {
            // selectedDate: mapLayerDate ? moment().set({date: mapLayerDate}).format('YYYY-MM-DD') : "",
            selectedDate: selectedDate ? selectedDate || moment(date).format('YYYY-MM-DD') : "",
            timeseriesArray: userData?.tabs[tabValue]?.timeseriesArray,
          },
        });
        const selectedDateValue = parseInt(mapDataRes?.data.selectedDate.split("-")[2])
      const defaultDateValue = parseInt(mapDataRes?.data.defaultDate.split("-")[2])

        setMapData(mapDataRes?.data?.finalResults)
        setMapLayerDate(selectedDateValue)
        setCurrentYear(new Date(mapDataRes?.data.selectedDate).getFullYear())
        setSelectedMonth(new Date(mapDataRes?.data.selectedDate).getMonth() + 1)

        setDefaultDate({
          day: defaultDateValue,
          month: new Date(mapDataRes?.data.defaultDate).getMonth() + 1,
          year: new Date(mapDataRes?.data.defaultDate).getFullYear(),
        })
        setIsMapReloading(false)
      }
    }
  }


  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const addRequestTab = async (item, index) => {

    const userRef = doc(db, 'users', userData.uid);
    const availableTabs = userData.tabs ? userData.tabs : []

    const updatedUserData = {
      ...userData,
      tabs: [...availableTabs, item].sort(compare),
      requestedTabs: userData.requestedTabs.filter((tab, i) => i !== index)
    }

    setUserData(updatedUserData)
    await updateDoc(userRef, updatedUserData);

  }


  const removeRequestTab = async (item, index) => {

    const userRef = doc(db, 'users', userData.uid);

    const updatedRequetedData = {
      ...userData,
      requestedTabs: userData.requestedTabs.filter((tab, i) => i !== index)
    }

    setUserData(updatedRequetedData)
    await updateDoc(userRef, updatedRequetedData);

  }
  return (
    <div className="dataArea">
      <Dialog
        open={showDialog || showCloneDialog}
        onClose={(e, reason) => {
          setShowDialog(false);
          setShowCloneDialog(false)
        }}
        classes={{ root: 'MuiDialog-root newTab' }}
        disableEscapeKeyDown
      >
        <div className="creatNewTab">

          <input type="text" name="name" id="name" placeholder='name of your tab' value={newTabName} onChange={e => setNewTabName(e.target.value)} />
          {showDialog && !showCloneDialog &&
            <FormControl>
              <FormLabel id="demo-controlled-radio-buttons-group" sx={{ fontSize: "18px" }}>Types :</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={typeChartOrMap}
                onChange={(e) => setTypeChartOrMap(e.target.value)}
              >
                <Box sx={{
                  display: "flex",
                  marginTop: "5px",
                  marginLeft: "10px",
                  gap: "10px"
                }}>
                  <FormControlLabel value="chart" control={<Radio />} label="Chart" />
                  <FormControlLabel value="map" control={<Radio />} label="Map" />
                </Box>
              </RadioGroup>
            </FormControl>
          }
          <Button style={{
            color: '#fff',
            backgroundColor: '#23263C',
            border: '1px solid #23263C',
            fontSize: '0.8rem',
            marginBottom: '1rem'
          }} variant="contained" onClick={addNewTab}>{isLoadingNewTab ? <CircularProgress size={20} /> : 'Add New Tab'}</Button>
        </div>
      </Dialog>
      <div className="dataArea__top">
        <div className="dataArea__tabs">
          {userData.tabs && userData.tabs.length > 0 && (
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="basic tabs example"
              variant="scrollable"
              style={{ width: '70vw' }}
            >
              {userData.tabs.map(({ name, id }, i) => (
                <Tab label={name} key={i} style={{ marginRight: '1rem' }} />
              ))}
            </Tabs>
          )}
        </div>


        <div style={{ display: "flex", alignItems: 'center' }}>

          <Stack direction="row" spacing={2}>

            {(userData?.requestedTabs && userData?.requestedTabs.length > 0) && <div>
              <Button
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                sx={{ backgroundColor: "red", color: "white" }}
              >
                <NotificationsIcon />
                {userData?.requestedTabs.length}
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ zIndex: 10000, boxShadow: '1px 1px 8px #858585' }}
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom-start' ? 'left top' : 'left bottom',
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                          autoFocusItem={open}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                        >
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <p style={{ fontSize: '18px', borderBottom: '1px solid green', margin: '5px' }}>Notifications</p>
                            {(userData?.requestedTabs && userData?.requestedTabs.length > 0) && userData?.requestedTabs?.map((item, i) => {
                              return (
                                <div style={{ display: "flex", flexDirection: "column", padding: "5px", width: '200px' }}>
                                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <MenuItem onClick={handleClose}>{item.name}</MenuItem>
                                    <div style={{ display: "flex", gap: "5px" }}>
                                      <button
                                        style={{
                                          color: '#fff',
                                          backgroundColor: 'green',
                                          border: '1px solid green',
                                          fontSize: 'rem',
                                          borderRadius: "5px",
                                          type: "button",
                                          cursor: 'pointer'
                                        }}
                                        variant="contained"
                                        onClick={() => addRequestTab(item, i)}
                                      >
                                        <CheckIcon style={{ width: '15px', height: '15px' }} />
                                      </button>
                                      <button style={{
                                        color: '#fff',
                                        backgroundColor: 'red',
                                        border: '1px solid red',
                                        fontSize: '0.5rem',
                                        borderRadius: "5px",
                                        type: "button",
                                        cursor: 'pointer'
                                      }}
                                        variant="contained"
                                        onClick={() => removeRequestTab(item, i)}
                                      >
                                        <CloseIcon style={{ width: '15px', height: '15px' }} />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </div>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>}
          </Stack>


          <Button style={{
            color: '#fff',
            backgroundColor: '#23263C',
            border: '1px solid #23263C',
          }} variant="contained" onClick={() => {
            setShowDialog(true)
            setShowCloneDialog(false)
          }}>Create A New Tab</Button>

        </div>

      </div>

      <ChartAndMapArea
        token={token}
        setDate={setDate}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        setClickedRegion={setClickedRegion}
        userData={userData}
        setselectedSubRegions={setselectedSubRegions}
        setSelectedCounty={setSelectedCounty}
        subRegions={subRegions}
        selectedSubRegions={selectedSubRegions}
        counties={counties}
        selectedCounty={selectedCounty}
        operators={operators}
        selectedOperator={selectedOperator}
        setSelectedOperator={setSelectedOperator}
        isMapClicked={isMapClicked}
        tabValue={tabValue}
        setTabValue={setTabValue}
        setUserData={setUserData}
        barData={barData}
        isLoading={isLoading}
        timeseriesChartData={timeseriesChartData}
        isMapReloading={isMapReloading}
        setShowCloneDialog={setShowCloneDialog}
        typeChartOrMap={typeChartOrMap}
        mapData={mapData}
        setMapData={setMapData}
        setEndMapDate={setEndMapDate}
        setMapStartDate={setMapStartDate}
        setMapLayerDate={setMapLayerDate}
        mapLayerDate={mapLayerDate}
        currentYear={currentYear}
        setCurrentYear={setCurrentYear}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        defaultDate={defaultDate}
      />
      {/* {userData.tabs && userData.tabs.length > 0 && (<div className="chartContent">{userData.tabs[value].data}</div>)} */}
    </div>
  );
}

export default DataArea