import React, { useState, useEffect } from 'react';
import ReactSelect from 'react-select';
import CloseIcon from '@mui/icons-material/Close';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import './DataArea.scss';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../App';

const years = [
  { value: 2021, label: '2021' },
  { value: 2022, label: '2022' },
  { value: 2023, label: '2023' },
];

const allMonths = [
  { value: 1, label: 'Jan' },
  { value: 2, label: 'Feb' },
  { value: 3, label: 'Mar' },
  { value: 4, label: 'Apr' },
  { value: 5, label: 'May' },
  { value: 6, label: 'Jun' },
  { value: 7, label: 'Jul' },
  { value: 8, label: 'Aug' },
  { value: 9, label: 'Sep' },
  { value: 10, label: 'Oct' },
  { value: 11, label: 'Nov' },
  { value: 12, label: 'Dec' },
];

const SingleSlider = ({ defaultDate, userData, tabValue, setDate, mapData, isDrawerOpen, loadShipsRef, isDrawerCollapsed, setMapLayerDate, mapLayerDate, currentYear, setCurrentYear, selectedMonth, setSelectedMonth }) => {
  const d = new Date();
  const currentMonth = d.getMonth() + 1;
  const [selectedDay, setSelectedDay] = useState(mapLayerDate);
  // const [currentYear, setCurrentYear] = useState(2023);
  // const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [monthOptions, setMonthOptions] = useState(allMonths);
  const [mobileDaysOptions, setMobileDaysOptions] = useState([])
  const [mobileDay, setMobileDay] = useState(1);
  const [showCalendarIcon, setShowCalendarIcon] = useState(false)
  const isMobileScreen = window.innerWidth < 600

  useEffect(() => {
    const newArr = [];
    // updated for new year
    if (currentYear === 2023) {
      monthOptions.forEach((month) => {
        if (month.value <= currentMonth) {
          newArr.push(month);
        }
      });
      setMonthOptions(newArr);
    } else {
      setMonthOptions(allMonths);
    }
  }, [currentYear]);

  useEffect(() => {
    if (isMobileScreen) {
      getPoints(true)
    }
  }, [])

  const selectDate = async (day) => {
    const docRef = doc(db, "users", userData.uid);
    const docSnap = await getDoc(docRef);
    const chartData = docSnap.data();
    let tabs = [...chartData.tabs];
    const date = `${currentYear}-${selectedMonth}-${day}`;
    setMapLayerDate(day)
    setSelectedDay(day);
    setDate(date);

    tabs[tabValue] = {
      ...tabs[tabValue],
      'selectedDateForMap': date
    }
    await updateDoc(docRef, { ...chartData, tabs });
  }

  const getPoints = (isMobile) => {
    const allPoints = [];

    const todayDate = d.getDate();

    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    const days = daysInMonth(selectedMonth, currentYear);
    if (isMobile) {
      const allDays = []
      for (let i = 1; i < days + 1; i++) {
        allDays.push({
          value: i, label: `${i}`
        })
      }
      setMobileDaysOptions(allDays);
    } else {
      for (let i = 1; i < days + 1; i++) {
        allPoints.push(
          <div className="singleSlider__point" key={i}>
            <div
              className={`singleSlider__date ${i > defaultDate.day &&
                  selectedMonth >= defaultDate.month &&
                  currentYear === 2023
                  ? 'singleSlider__date--future'
                  : ''
                }`}
              onClick={() => selectDate(i)}
            >
              <div
                className={`${i === selectedDay || i === mapData?.selectedDate ? 'singleSlider__date--selected' : ''
                  }`}
              >
                {i}
              </div>
            </div>
          </div>
        );
      }
      return allPoints;
    }

  };

  return (
    <div className={`singleSliderBox ${isDrawerOpen ? isDrawerCollapsed ? 'singleSliderBox__collapsed' : 'singleSliderBox__drawer' : ''
      }`}>
      {showCalendarIcon ? (
        <div>
          <CalendarMonthIcon
            style={{ cursor: 'pointer', color: 'rgb(108, 108, 108)', fontSize: "2.6rem" }}
            onClick={() => setShowCalendarIcon(false)}
          />
        </div>
      ) : (
        <div className='singleSlider'>
          <div className="singleSlider__close">
            <CloseIcon
              style={{ cursor: 'pointer', color: 'rgb(108, 108, 108)' }}
              onClick={() => setShowCalendarIcon(true)}
            />
          </div>
          <div className="singleSlider__dropDowns">
            <ReactSelect
              options={years}
              menuPlacement="top"
              onChange={(e) => setCurrentYear(e.value)}
              value={years.filter(function (option) {
                return option.value === currentYear;
              })}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  fontSize: '1.3rem',
                  minWidth: isMobileScreen ? 80 : 'unset',
                }),
              }}
            />
            <ReactSelect
              options={monthOptions}
              menuPlacement="top"
              onChange={(e) => setSelectedMonth(e.value)}
              value={monthOptions.filter(function (option) {
                return option.value === selectedMonth;
              })}
              styles={{
                container: (provided, state) => ({
                  ...provided,
                  margin: isMobileScreen ? '0 1rem' : '5px 0 0 0',
                  fontSize: '1.3rem',
                  minWidth: isMobileScreen ? 80 : 'unset',
                }),
              }}
            />

            {isMobileScreen && (
              <ReactSelect
                options={mobileDaysOptions}
                menuPlacement="top"
                onChange={(e) => {
                  const date = `${currentYear}-${selectedMonth}-${e.value}`;
                  // leafLetRef.current.loadShips(date, date);
                  if (loadShipsRef?.current !== null) loadShipsRef?.current(date, date)
                  return setMobileDay(e.value);
                }}
                value={mobileDaysOptions.filter(function (option) {
                  return option.value === mobileDay;
                })}
                styles={{
                  container: (provided, state) => ({
                    ...provided,
                    fontSize: '1.3rem',
                    minWidth: 80,
                  }),
                }}
              />
            )}
          </div>

          <div className="singleSlider__allPoints">
            {getPoints()}
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleSlider;
