import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { CircularProgress } from '@mui/material';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const BarChart = ({barData, isLoading}) => {
  const {values2020, values2021, values2022 } = barData;
  
const isMobileScreen = window.innerWidth < 600;
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
      labels: {
        boxWidth: isMobileScreen ? 30 : 40,
        boxHeight: isMobileScreen ? 15 : 20,
        font: {
          size: isMobileScreen ? 11 : 14
        }, 
      }
    },
    title: {
      display: true,
      text: `Monthly Completions`,
      font: {
        size: isMobileScreen ? 14 : 18
      }
    },
  },
  scales: {
    x: {
      ticks: {
        font: {
          size: isMobileScreen ? 11 : 16,
          weight: isMobileScreen ? '' :'bold'
        }
      },
    },
    y: {
      ticks: {
        font: {
          size: isMobileScreen ? 11 : 16,
          weight: isMobileScreen ? '' :'bold'
        }
      },
      beginAtZero: true,
      display: true,
      title: {
        display: true,
        text: 'Completions',
        color: '#333',
        font: {
          // family: 'Times',
          size: isMobileScreen ? 14 : 20,
          style: isMobileScreen ? '' : 'normal',
          lineHeight: 1.2,
        },
        padding: { top: 30, left: 0, right: 20, bottom: 0 },
      },
    },
  },
};

  const data = {
    labels,
    datasets: [
      {
        label: '2020',
        data: values2020,
        borderColor: 'rgb(60,61,107)',
        backgroundColor: 'rgba(60,61,107,0.5)',
          pointRaduis: 0,
      },
      {
        label: '2021',
        data: values2021,
        borderColor: 'rgb(248,83,72)',
          backgroundColor: 'rgba(248,83,72,0.5)',
          pointRaduis: 0,
      },
      {
        label: '2022',
        data: values2022,
        borderColor: 'rgb(36,160,202)',
        backgroundColor: 'rgba(36,160,202,0.5)',
      },
    ],
  };
  return (

  <div className="frackingData">
  {isLoading ? (
    <div className="loadingBox">
      <CircularProgress size={80} />
    </div>
  ) : (
    <Bar options={options} data={data} />
  )}
</div>)
}

export default BarChart;