import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import GetAppIcon from '@mui/icons-material/GetApp';
import XLSX from 'xlsx';
import {
  Button,
  Dialog,
  Snackbar,
  SnackbarContent,
  CircularProgress,
  Box,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

import D3Map from './D3Map';
import FrackingChart from './FrackingChart';
import Footer from '../../components/footer/Footer';
import Map from './Leaflet';
import MapSmall from './SmallLeafletMap';
import './geoml.scss';
import BarChart from './BarChart';
import {
  useStyles,
  getXLSData,
  columnSchema,
  getBarData,
  getLineChartData,
  getMonthlyDataforXLS,
  getFlagDataForSubRegion,
} from './utils';
import DataArea from './DataArea/DataArea'

const Hyperion = ({ token, logout, isloggedIn, userData, setUserData }) => {
  const [frackingData, setFrackingData] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [datesAndLables, setDatesAndLabel] = useState({
    dates2021: [],
    dates2022: [],
  });
  const [rigsDatesAndLables, setRigsDatesAndLabel] = useState({
    dates2021: [],
    dates2022: [],
  });
  const [ducsDatesAndLables, setDucsDatesAndLabel] = useState({
    dates2022: [],
    dates2021: [],
    dates2020: [],
    dates2019: [],
    dates2018: [],
    dates2017: [],
    dates2016: [],
  });
  const [barData, setBarData] = useState({
    values2020: [],
    values2021: [],
    values2022: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [clickedRegion, setClickedRegion] = useState({
    Gulf: false,
    Midwest: false,
    Northeast: false,
    West: false,
    Southeast: false
  });
  const [showDialog, setShowDialog] = useState(false);
  const [userHasAccess, setUserHasAccess] = useState(false);
  const [operators, setOperators] = useState([]);
  const [selectedOperator, setSelectedOperator] = useState('');
  const [subRegions, setSubRegions] = useState([]);
  const [selectedSubRegions, setselectedSubRegions] = useState([]);
  const [counties, setCounties] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState('');
  const [isMapClicked, setIsMapClicked] = useState(false);
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [viewChart, setViewChart] = useState(true);
  const [resultMap, setResultMap] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [regionsAndSubregions, setRegionsAndSubregions] = useState(null);
  const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false)
  const [newPassword, setNewPassword] = useState('')
  const [isPasswordChanging, setIsPasswordChanging] = useState(false)
  const [isPwChangeSnackBarOpen, setIsPwChangeSnackBarOpen] = useState(false)
  const chartRef = useRef(null);
  const rigRef = useRef(null);
  const ducRef = useRef(null);
  const styles = useStyles();

  useEffect(() => {
    setSelectedOperator(null);
    //  setselectedSubRegions(null);
    setSelectedCounty(null);

    if (localStorage.getItem('showSnack')) {
      setIsSnackBarOpen(true);
      localStorage.removeItem('showSnack');
    }
  }, []);

  useEffect(() => {
    getServerRegionsData();
  }, []);

  const getServerRegionsData = async () => {
    if (!regionsAndSubregions) {
      const regions = await axios.get('/geoml/regions', {
        headers: { Authorization: 'Bearer ' + token },
      });
      setRegionsAndSubregions(regions.data);
    }
  };

  const changePassword = async () => {
    try {
      setIsPasswordChanging(true)
      console.log(userData, ':userData')
      const res = await axios.post('/users/change-password', {
        headers: { Authorization: 'Bearer ' + token },
        uid: userData.uid,
        newPassword
      }); 

      if(res.data.message) {
        setIsPasswordChanging(false)
        setIsPwChangeSnackBarOpen(true)
        setShowChangePasswordDialog(false)
      }
    } catch (err) {
      setIsPasswordChanging(false)
      console.log(err, ' :err')
      setShowChangePasswordDialog(false)
    }
  
  }

  useEffect(() => {
    userHasAccess && getInitialData();
  }, [userHasAccess]);

  useEffect(() => {

    onFilterChange();
  }, [clickedRegion, selectedOperator, selectedSubRegions, selectedCounty]);

  useEffect(() => {
    const userHasAccess =
      userData &&
      (userData.role === 'super_user' ||
        userData.role === 'admin' ||
        userData.role === 'co-worker' || 
        userData.role === 'hyperion_customer' ||
        userData.role === 'vulcan_and_hyperion_customer' ||
        (userData.role === 'temporary_hyperion_user' &&
          userData.expiry_date.seconds > Date.now() / 1000));
    setUserHasAccess(userHasAccess);
  }, [userData]);

  useEffect(() => {
    const { Gulf, Midwest, Northeast, West, Southeast } = clickedRegion;
    const isMapClicked = Gulf || Midwest || Northeast || West || Southeast;
    setIsMapClicked(isMapClicked);
  }, [clickedRegion]);

  // useEffect(() => {
  //   if (frackingData.length === 0) {
  //     initialData.length !== 0 && setIsSnackBarOpen(true);
  //     getChartData();
  //   } else {
  //     console.log('getting chart data')
  //     setIsSnackBarOpen(false);
  //     getChartData();
  //   }
  // }, [frackingData]);

  const getChartData = async () => {
    const { Gulf, Midwest, Northeast, West } = clickedRegion;
    setIsLoading(true);
    const liveData = await axios.get('/geoml/data', {
      headers: { Authorization: 'Bearer ' + token },
      params: {
        selectedOperator,
        selectedSubRegion: selectedSubRegions,
        selectedCounty,
        Gulf,
        Midwest,
        Northeast,
        West,
        token,
      },
    });
    setResultMap(liveData.data.resultMap.recordset);
    const allData = liveData.data;
    const resultCount = allData.resultCount.recordset.reverse();
    const drillCount = allData.drillCount.recordset.reverse();
    const ducCount = allData.ducCount.recordset.reverse();
    const monthlyCount = allData.monthlyCount;
    let operators = [];
    allData.distinctOperators.recordset.forEach(
      ({ operator_name }) => operator_name && operators.push(operator_name)
    );
    setOperators(operators);

    if (resultCount.length === 0 && drillCount.length === 0 && ducCount.length === 0 && monthlyCount.recordset.length === 0) {
      setIsSnackBarOpen(true)
    }
    const { newDates2022, newDates2021 } = getLineChartData(
      resultCount,
      clickedRegion,
      'fracks'
    );
    setDatesAndLabel({
      dates2022: newDates2022,
      dates2021: newDates2021,
    });

    const { newDates2022: rigDates2022, newDates2021: rigDates2021 } =
      getLineChartData(drillCount, clickedRegion, 'rigs');
    setRigsDatesAndLabel({
      dates2022: rigDates2022,
      dates2021: rigDates2021,
    });

    const ducCountChartData = getLineChartData(
      ducCount,
      clickedRegion,
      'ducs'
    );
    setDucsDatesAndLabel({
      dates2022: ducCountChartData.newDates2022,
      dates2021: ducCountChartData.newDates2021,
      dates2020: ducCountChartData.newDates2020,
      dates2019: ducCountChartData.newDates2019,
      dates2018: ducCountChartData.newDates2018,
      dates2017: ducCountChartData.newDates2017,
      dates2016: ducCountChartData.newDates2016,
    });

    const barChartCount = getBarData(monthlyCount.recordset, clickedRegion);

    setBarData(barChartCount);
    setIsLoading(false);
  };

  const onFilterChange = () => {
    console.log('initial call')
    // This is for chart visuals using liveData
    operators.length === 0 && getChartData();

    // The below code is to load appropriate filters
    const { Gulf, Midwest, Northeast, West } = clickedRegion;
    const arrOfConditions = [];
    if (Gulf || Midwest || Northeast || West) {
      Gulf && arrOfConditions.push('gulf');
      Midwest && arrOfConditions.push('midwest');
      Northeast && arrOfConditions.push('northeast');
      West && arrOfConditions.push('west');
      let filteredData = initialData.filter((item) =>
        arrOfConditions.includes(item.skylar_region)
      );
      // let operators = [];
      // filteredData.forEach((item) => operators.push(item.di_OperatorName));
      // operators = removeDuplicatesAndSortFromArr(operators);
      // setOperators(operators);

      // if (selectedOperator && selectedOperator !== '') {
      //   filteredData = filteredData.filter(
      //     (item) => item.di_OperatorName === selectedOperator
      //   );
      // }
      let subRegions = [];
      filteredData.forEach((item) => subRegions.push(item.sub_region));
      subRegions = removeDuplicatesAndSortFromArr(subRegions);
      let counties = [];
      filteredData.forEach((item) => counties.push(item.county));
      counties = removeDuplicatesAndSortFromArr(counties);
      if (selectedSubRegions && selectedSubRegions.length !== 0) {
        filteredData = filteredData.filter((item) =>
          selectedSubRegions.includes(item.sub_region)
        );
        let newCounties = [];
        filteredData.forEach((item) => newCounties.push(item.county));
        newCounties = removeDuplicatesAndSortFromArr(newCounties);
        counties = newCounties;
      }

      if (selectedCounty && selectedCounty !== '') {
        filteredData = filteredData.filter(
          (item) => item.county === selectedCounty
        );
      }
      console.log(counties, 'counties from filter')
      setCounties(counties);
      setSubRegions(subRegions);
      setFrackingData(filteredData);
    } else {
      // let operators = [];
      // initialData.forEach((item) => operators.push(item.di_OperatorName));
      // operators = removeDuplicatesAndSortFromArr(operators);
      // setOperators(operators);

      // if (selectedOperator && selectedOperator !== '') {
      //   // Apply Only Operator filter
      //   const filteredData = initialData.filter(
      //     (item) => item.di_OperatorName === selectedOperator
      //   );
      //   setFrackingData(filteredData);
      // } else {
      // Get All Data
      setFrackingData(initialData);
      // }
    }
  };

  const removeDuplicatesAndSortFromArr = (a) => {
    var seen = {};
    return a
      .filter(function (item) {
        return seen.hasOwnProperty(item) ? false : (seen[item] = true);
      })
      .sort(function (a, b) {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
  };

  const getInitialData = async () => {
    setIsLoading(true);
    const frackingData = await axios.get('/geoml', {
      headers: { Authorization: 'Bearer ' + token },
    });
    // The .sort function sorts the objects from latest to oldest object
    let dataArr = frackingData.data.data.sort(function (a, b) {
      return new Date(b.acquired) - new Date(a.acquired);
    });

    // let operators = [];
    // dataArr.forEach((item) => operators.push(item.di_OperatorName));
    // operators = removeDuplicatesAndSortFromArr(operators);
    // setOperators(operators);
    // Since we get the data from dec31-jan1, but we render the data from jan1-dec31,
    // so we have to reverse the data
    setInitialData(dataArr.reverse());
    setFrackingData(dataArr);
    setIsLoading(false);
  };

  const downloadXLS = () => {
    const frack2021Ws = XLSX.utils.json_to_sheet(
      getXLSData(datesAndLables.dates2021, false)
    );
    const frack2022Ws = XLSX.utils.json_to_sheet(
      getXLSData(datesAndLables.dates2022, false)
    );
    const rigs2021Ws = XLSX.utils.json_to_sheet(
      getXLSData(rigsDatesAndLables.dates2021, false)
    );
    const rigs2022Ws = XLSX.utils.json_to_sheet(
      getXLSData(rigsDatesAndLables.dates2022, false)
    );
    const monthly2020 = XLSX.utils.json_to_sheet(
      getMonthlyDataforXLS(barData.values2020, false)
    );
    const monthly2021 = XLSX.utils.json_to_sheet(
      getMonthlyDataforXLS(barData.values2021, false)
    );
    const monthly2022 = XLSX.utils.json_to_sheet(
      getMonthlyDataforXLS(barData.values2022, false)
    );
    const ducs2016 = XLSX.utils.json_to_sheet(
      getXLSData(ducsDatesAndLables.dates2016, false, true)
    );
    const ducs2017 = XLSX.utils.json_to_sheet(
      getXLSData(ducsDatesAndLables.dates2017, false, true)
    );
    const ducs2018 = XLSX.utils.json_to_sheet(
      getXLSData(ducsDatesAndLables.dates2018, false, true)
    );
    const ducs2019 = XLSX.utils.json_to_sheet(
      getXLSData(ducsDatesAndLables.dates2019, false, true)
    );
    const ducs2020 = XLSX.utils.json_to_sheet(
      getXLSData(ducsDatesAndLables.dates2020, false, true)
    );
    const ducs2021 = XLSX.utils.json_to_sheet(
      getXLSData(ducsDatesAndLables.dates2021, false, true)
    );
    const ducs2022 = XLSX.utils.json_to_sheet(
      getXLSData(ducsDatesAndLables.dates2022, false, true)
    );
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, frack2021Ws, 'Frack 2021');
    XLSX.utils.book_append_sheet(wb, frack2022Ws, 'Frack 2022');
    XLSX.utils.book_append_sheet(wb, rigs2021Ws, 'Rigs 2021');
    XLSX.utils.book_append_sheet(wb, rigs2022Ws, 'Rigs 2022');
    XLSX.utils.book_append_sheet(wb, monthly2020, 'Monthly 2020');
    XLSX.utils.book_append_sheet(wb, monthly2021, 'Monthly 2021');
    XLSX.utils.book_append_sheet(wb, monthly2022, 'Monthly 2022');
    XLSX.utils.book_append_sheet(wb, ducs2016, 'Ducs 2016');
    XLSX.utils.book_append_sheet(wb, ducs2017, 'Ducs 2017');
    XLSX.utils.book_append_sheet(wb, ducs2018, 'Ducs 2018');
    XLSX.utils.book_append_sheet(wb, ducs2019, 'Ducs 2019');
    XLSX.utils.book_append_sheet(wb, ducs2020, 'Ducs 2020');
    XLSX.utils.book_append_sheet(wb, ducs2021, 'Ducs 2021');
    XLSX.utils.book_append_sheet(wb, ducs2022, 'Ducs 2022');

    // let filename = 'data';
    // const { Gulf, Midwest, Northeast, West } = clickedRegion;

    // if (selectedOperator && selectedOperator !== '') {
    //   filename = filename + '.' + selectedOperator;
    // }
    // if (Gulf) {
    //   filename = filename + '.Gulf';
    // }
    // if (Midwest) {
    //   filename = filename + '.Midwest';
    // }
    // if (Northeast) {
    //   filename = filename + '.Northeast';
    // }
    // if (West) {
    //   filename = filename + '.West';
    // }
    // if (selectedSubRegions && selectedSubRegions !== '') {
    //   filename = filename + '.' + selectedSubRegions;
    // }
    // if (selectedCounty && selectedCounty !== '') {
    //   filename = filename + '.' + selectedCounty;
    // }

    XLSX.writeFile(wb, `hyperion.xlsx`);
    setShowDialog(false);
  };
  return (
    <div className="geoml admin">
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={4000}
        onClose={() => setIsSnackBarOpen(false)}
        message="No Data for those filters"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <SnackbarContent
          message={
            userHasAccess ? (
              'No Data for those filters'
            ) : (
              <div>
                Account Created!{' '}
                <a href="mailto:info@synmax.com" style={{ color: '#fff' }}>
                  Contact Us
                </a>{' '}
                to get full access.
              </div>
            )
          }
        />
      </Snackbar>

      <Snackbar
        open={isPwChangeSnackBarOpen}
        autoHideDuration={4000}
        onClose={() => setIsPasswordChanging(false)}
        message="Password Changed"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />


      <Dialog
        open={showChangePasswordDialog}
        onClose={() => setShowChangePasswordDialog(false)}
      >
        <div className="changePassword">
          <div className="changePassword__heading">Change Password: </div>
          <div className="changePassword__input">
            <TextField
              id="outlined-basic"
              label="New Password"
              variant="standard"
              type="password"
              name="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <LoadingButton loading={isPasswordChanging} variant="outlined" color='primary' onClick={changePassword}>Update</LoadingButton>
        </div>
      </Dialog>

      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        classes={{ root: 'MuiDialog-root downloadCsv--geoml' }}
      >
        <div className="downloadCsv__dialog">
          <div className="downloadCsv__table">
            <div className="downloadCsv__tables">
              <div className="singleTable">
                <div className="singleTable__heading">Frack(2021)</div>
                <DataGrid
                  rows={getXLSData(datesAndLables.dates2021, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
              <div className="singleTable">
                <div className="singleTable__heading">Frack(2022)</div>
                <DataGrid
                  rows={getXLSData(datesAndLables.dates2022, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
              <div className="singleTable">
                <div className="singleTable__heading">Rigs(2021)</div>
                <DataGrid
                  rows={getXLSData(rigsDatesAndLables.dates2021, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
              <div className="singleTable">
                <div className="singleTable__heading">Rigs(2022)</div>
                <DataGrid
                  rows={getXLSData(rigsDatesAndLables.dates2022, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>

              <div className="singleTable">
                <div className="singleTable__heading">Monthly(2020)</div>
                <DataGrid
                  rows={getMonthlyDataforXLS(barData.values2020, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>

              <div className="singleTable">
                <div className="singleTable__heading">Monthly(2021)</div>
                <DataGrid
                  rows={getMonthlyDataforXLS(barData.values2021, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>

              <div className="singleTable">
                <div className="singleTable__heading">Monthly(2022)</div>
                <DataGrid
                  rows={getMonthlyDataforXLS(barData.values2022, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>

              <div className="singleTable">
                <div className="singleTable__heading">DUCs(2016)</div>
                <DataGrid
                  rows={getXLSData(ducsDatesAndLables.dates2016, true, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
              <div className="singleTable">
                <div className="singleTable__heading">DUCs(2017)</div>
                <DataGrid
                  rows={getXLSData(ducsDatesAndLables.dates2017, true, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
              <div className="singleTable">
                <div className="singleTable__heading">DUCs(2018)</div>
                <DataGrid
                  rows={getXLSData(ducsDatesAndLables.dates2018, true, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
              <div className="singleTable">
                <div className="singleTable__heading">DUCs(2019)</div>
                <DataGrid
                  rows={getXLSData(ducsDatesAndLables.dates2019, true, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
              <div className="singleTable">
                <div className="singleTable__heading">DUCs(2020)</div>
                <DataGrid
                  rows={getXLSData(ducsDatesAndLables.dates2020, true, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
              <div className="singleTable">
                <div className="singleTable__heading">DUCs(2021)</div>
                <DataGrid
                  rows={getXLSData(ducsDatesAndLables.dates2021, true, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
              <div className="singleTable">
                <div className="singleTable__heading">DUCs(2022)</div>
                <DataGrid
                  rows={getXLSData(ducsDatesAndLables.dates2022, true, true)}
                  columns={columnSchema}
                  pageSize={4}
                  rowsPerPageOptions={[8]}
                />
              </div>
            </div>

            <div id="download">
              <Button variant="outlined" onClick={downloadXLS}>
                Download XLS
              </Button>
            </div>
          </div>
          <br />
        </div>
      </Dialog>

      <div className="geoml__navbar">
        <div className="geoml__navbar--logo">
          <img src="/new_logo.png" alt="Synmax" />
        </div>
        <div className="geoml__navbar--title">
          Hyperion
        </div>
        <div
          className="geoml__navbar--auth"
        // onClick={() => setShowDialog(true)}
        >
          {isloggedIn ? (
            <>
              <Button
                onClick={() => setShowChangePasswordDialog(true)}
                style={{
                  color: '#000',
                  backgroundColor: '#D8DDD8',
                  textTransform: 'unset',
                  marginRight: 15,
                  fontWeight: 400
                }}
              >
                Change Password
              </Button>
              <Button
                color="error"
                onClick={logout}
                style={{
                  color: '#fff',
                  backgroundColor: '#F85348',
                  border: '1px solid #F85348',
                }}
              >
                Logout
              </Button>
            </>
          ) : (
            <Link
              style={{ textDecoration: 'none', color: '#24A0CA' }}
              to="/login"
            >
              Login
            </Link>
          )}
        </div>
      </div>

      {userHasAccess &&
        <DataArea
          userData={userData}
          setUserData={setUserData}
          selectedRegion={selectedRegion}
          setSelectedRegion={setSelectedRegion}
          setClickedRegion={setClickedRegion}
          token={token}
          setselectedSubRegions={setselectedSubRegions}
          setSelectedCounty={setSelectedCounty}
          subRegions={subRegions}
          selectedSubRegions={selectedSubRegions}
          counties={counties}
          selectedCounty={selectedCounty}
          operators={operators}
          selectedOperator={selectedOperator}
          setSelectedOperator={setSelectedOperator}
          isMapClicked={isMapClicked}
          clickedRegion={clickedRegion}
          barData={barData}
          isLoading={isLoading}
        />}
      {!userHasAccess && (
        <div className="landingPage">
          <div className="landingPage__content">
            <h1>
              Hyperion, every frac crew and drilling rig in near real time
            </h1>
            <Button
              onClick={(e) => {
                window.location = 'mailto:info@synmax.com';
              }}
              variant="contained"
              id="contact"
              style={{ width: 280, marginRight: 20 }}
            >
              {isloggedIn
                ? 'Contact Us For Full Access'
                : 'Contact Us For More Details'}
            </Button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Hyperion;
