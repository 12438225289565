import React, { useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  Navigate,
} from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { CircularProgress, Snackbar, SnackbarContent } from '@mui/material';

import Auth from './pages/auth/Auth';
import Admin from './pages/admin/Admin';
import Hyperion from './pages/hyperion/Hyperion';
import './App.scss'
import ResetPassword from './components/ResetPassword';
import { dataCenterEricTabs, ericTabs } from './constants';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);

export default function Ship() {
  const [token, setToken] = useState('');
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [isTokenLoading, setIsTokenLoading] = useState(true);
  const [isNewUser, setIsNewUser] = useState(true);
  const [forgotPassword, setForgotPassword] = useState(false);
  // userData ~ user profile (has email and role)
  const [userData, setUserData] = useState({});
  const [uid, setUid] = useState('');
  const [snackBarMsg, setSnackBarMsg] = useState('');

  let auth = getAuth();
  let isloggedIn = auth.currentUser;

  useEffect(() => {
    console.log(userData, 'user data changes')
  },[userData])


  useEffect(() => {
    // This function from firebase is called when our authentication state changes(eg: logged in/ logged out)
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        const { uid, email } = user;
        setUid(uid)
        const docRef = doc(db, 'users', uid);
        const docSnap = await getDoc(docRef);
        const userData = docSnap.data();
        setUserData({...userData, uid});
        const token = await user.getIdToken();
        console.log(userData, 'userData')
        if (uid && !userData) {
          // It means this is a new user, so it doesn't have any profile data yet
          // So we create a profile for this user on firebase
          await setDoc(doc(db, 'users', uid), {
            email,
            role: 'new_user',
          });
        }

        // We are checking the user's role
        // As you need to be a customer, co-worker, admin, or super-user to view the app
        const role = userData ? userData.role : '';
        if (role === 'super_user' || role === 'admin' || role === 'co-worker' || role === 'hyperion_customer' || role === 'vulcan_and_hyperion_customer' ||(role === 'temporary_hyperion_user' && userData?.expiry_date?.seconds > Date.now()/1000)) {
          setToken(token);

          if(userData) {
            if(!userData.tabs) {
              await setDoc(doc(db, 'users', uid), {
                ...userData,
                tabs: ericTabs,
                dataCenterTabs: dataCenterEricTabs,
                uid
              });
              setUserData({
                ...userData,
                tabs: ericTabs,
                uid
              })
            } else {
              // Adds tabType to tabs that doesn't have it
              // And since we introduced the idea of tabType only after we created the map tab
              // We can safely assume tabType: "chart"
              if(userData.tabs.length > 0) {
                const newTabsArray = []

                userData.tabs.forEach(tab => {
                  if(tab.tabType) {
                    newTabsArray.push(tab)
                  } else {
                    newTabsArray.push({...tab, tabType: 'chart'})
                  }
                })
                console.log(newTabsArray, 'newTabsArray')
                await setDoc(doc(db, 'users', uid), {
                  ...userData,
                  tabs: newTabsArray,
                  uid
                });
                setUserData({
                  ...userData,
                  tabs: newTabsArray,
                  uid
                })
              }
            }
          }
          setIsTokenLoading(false);
        } else {
          if (token) {
              if(window.location.href.includes('login')) {
                !userData && localStorage.setItem('showSnack', true)
                document.location.href = '/'
              }
            
          } else {
              if(window.location.href.includes('login')) {
                document.location.href = '/'
              }
          
          }
        }
        setIsTokenLoading(false);
      } else {
        setIsTokenLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    setInterval(() => {
      if (isloggedIn) {
        refreshToken();
      }
    }, 5000);

    const refreshToken = async () => {
      const token = await isloggedIn.getIdToken();
      setToken(token)
    };
  }, [isloggedIn]);

  const logout = () => {
    signOut(auth)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        console.log(error, 'err');
      });
  };

  const PublicOutlet = () => {
    return isloggedIn && token !== '' ? (
      <Navigate to='/' />
    ) : (
      <Outlet />
    );
  };

  const AdminRoute = () => {
    // We have already prevented 'new_user' from accessing any page in the "onAuthStateChanged" function above
    // This route makes sure that only people in our company has access to certain pages
    const { role } = userData;
    if (isloggedIn) {
      if (role === 'super_user' || role === 'admin' || role === 'co-worker') {
        return <Outlet />;
      } else {
        setSnackBarMsg("You don't have access to that page");
        setIsSnackBarOpen(true);
        return <Navigate to="/" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  };

  return isTokenLoading ? (
    <div className="loadingPage">
      <CircularProgress />
    </div>
  ) : (
    <>
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackBarOpen(false)}
        message={snackBarMsg}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      >
        <SnackbarContent
          message={<span id="client-snackbar">{snackBarMsg}</span>}
        />
      </Snackbar>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<PublicOutlet />}>
              <Route
                path=""
                element={
                  <Auth
                    isNewUser={isNewUser}
                    setIsNewUser={setIsNewUser}
                    forgotPassword={forgotPassword}
                    setForgotPassword={setForgotPassword}
                  />
                }
              />
            </Route>
            <Route path="/admin" element={<AdminRoute />}>
              <Route path="" element={<Admin userData={userData} token={token} />} />
            </Route>

            <Route path="/reset-password" element={<ResetPassword setIsSnackBarOpen={setIsSnackBarOpen} setSnackBarMsg={setSnackBarMsg}/>}/>

            <Route path="/" element={<Hyperion token={token} logout={logout} isloggedIn={isloggedIn} userData={userData} setUserData={setUserData}/>} />

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
    </>
  );
}
