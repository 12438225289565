import React, { useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { useEffect } from 'react';
import XLSX from 'xlsx';
import moment from 'moment';

export const columnSchema = [
  {
    field: 'id',
    headerName: 'row',
    width: 20,
    hide: true,
  },
  { field: 'date', headerName: 'Date', width: 120 },
  { field: 'count', headerName: 'Count', width: 100 },
];

const getXLSData = (labels, datasets) => {
  const finalArr = [];

  labels.forEach((label, i) => {
    const objToPush = {
      id: i + 1,
      date: moment(labels[i]).format('YYYY-MM-DD'),
    };
    datasets.forEach(
      ({ data, label }) =>
        (objToPush[label] = isNaN(data[i]) ? 'No Data' : data[i])
    );
    finalArr.push(objToPush);
  });
  return finalArr;
};

const getColumnSchema = (datasets) => {
  const columnSchema = [
    {
      field: 'id',
      headerName: 'row',
      width: 20,
      hide: true,
    },
    { field: 'date', headerName: 'Date', width: 120 },
  ];

  datasets.forEach(({ label }) =>
    columnSchema.push({ field: label, headerName: label, width: 100 })
  );
  return columnSchema;
};

const DownloadXLS = ({ labels, datasets }) => {
  const [showDownloadXLSDialog, setShowDownloadXLSDialog] = useState(false);
  const [columnsSchema, setColumnsSchema] = useState([]);

  useEffect(() => {
    const newSchema = getColumnSchema(datasets);
    setColumnsSchema(newSchema);
  }, [datasets]);

  const downloadXLSForTimeseries = () => {
    const data = getXLSData(labels, datasets).map((data) => {
      return {
        ...data,
        date: moment(data.date).diff('1899-12-30', 'days'),
      };
    });
    const timeseriesData = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();

    XLSX.utils.book_append_sheet(wb, timeseriesData, 'Hyperion Data');
    XLSX.writeFile(wb, `hyperion.xlsx`);
    setShowDownloadXLSDialog(false);
  };
  return (
    <>
      <Dialog
        open={showDownloadXLSDialog}
        onClose={() => setShowDownloadXLSDialog(false)}
        classes={{ root: 'MuiDialog-root downloadXLS__dialog' }}>
        <div className='hyperionv2__downloadXLS'>
          <DataGrid
            rows={getXLSData(labels, datasets)}
            columns={columnsSchema}
            pageSize={6}
            rowsPerPageOptions={[8]}
          />
        </div>

        <Button
          style={{
            color: '#fff',
            backgroundColor: '#333',
            border: '1px solid #ccc',
            float: 'right',
            fontSize: 12,
            width: '10rem',
            marginTop: 10,
          }}
          onClick={downloadXLSForTimeseries}>
          Download XLS
        </Button>
      </Dialog>

      <Button
        style={{
          color: '#000',
          backgroundColor: '#f092dd',
          border: '1px solid #ccc',
          float: 'right',
          fontSize: 12,
          marginRight: 10,
        }}
        onClick={() => setShowDownloadXLSDialog(true)}>
        Download XLS
      </Button>
    </>
  );
};

export default DownloadXLS;
