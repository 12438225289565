import React, { useState } from 'react';
import { TextField, Button, Snackbar } from '@mui/material';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from 'firebase/auth';
import LoadingButton from '@mui/lab/LoadingButton';
import './auth.scss';
import SingupForm from './SingupForm';

const Auth = ({
  isNewUser,
  setIsNewUser,
  forgotPassword,
  setForgotPassword,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errMessage, setErrMessage] = useState('');
  const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const auth = getAuth();

  const signInUser = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrMessage('');

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        setIsLoading(false);
        const user = userCredential.user;
        console.log(user, 'user');
        clearState();
      })
      .catch((error) => {
        setIsLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        setErrMessage(errorMessage);
      });
  };

  const sendResetEmail = () => {
    setIsLoading(true);
    setErrMessage('');

    sendPasswordResetEmail(auth, email)
      .then(() => {
        setIsLoading(false);
        setIsSnackBarOpen(true);
        clearState('');
      })
      .catch((error) => {
        setIsLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        setErrMessage(errorMessage);
      });
  };

  const clearState = () => {
    setPassword('');
    setFirstName('');
    setLastName('');
    setEmail('');
  };
  return (
    <div className="auth">
      <Snackbar
        open={isSnackBarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackBarOpen(false)}
        message="Reset Email Sent!"
      />
      <div className="auth__mainHeading">Welcome to Hyperion</div>
      {errMessage !== '' && <div className="errorMessage">{errMessage}</div>}
      {forgotPassword ? (
        <div className="auth__formBox">
          <form className="auth__inputs auth__signUp">
            <div className="auth__email">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="standard"
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <LoadingButton
              variant="outlined"
              className="button"
              onClick={sendResetEmail}
              loading={isLoading}
              type="submit"
              style={{
                width: 250,
              }}
            >
              Send Reset Email
            </LoadingButton>
          </form>
        </div>
      ) : (
        <div className="auth__forms">
          <div className="auth__formBox auth__formBox--signIn">
            <div className="auth__header">Already Have An Account? Sign In</div>
            <form className="auth__inputs auth__signUp auth__signIn">
              <div className="auth__email">
                <TextField
                  id="outlined-basic"
                  label="Email"
                  variant="standard"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="auth__password">
                <TextField
                  id="password"
                  label="Password"
                  variant="standard"
                  type="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <LoadingButton
                variant="outlined"
                className="button"
                onClick={signInUser}
                loading={isLoading}
                type="submit"
              >
                Sign In
              </LoadingButton>
            </form>
          </div>

          <SingupForm setErrMessage={setErrMessage} />
        </div>
      )}
      {forgotPassword ? (
         <div className="switchForm resetPassword">
        <Button
          onClick={(e) => {
            return setForgotPassword(false);
          }}
        >
          Go Back
        </Button>
        </div>
      ) : (
        <div className="switchForm resetPassword">
          <div className="switch">
            Forgot Password?
            <Button
              onClick={(e) => {
                clearState();
                setIsNewUser(!isNewUser);
                return setForgotPassword(true);
              }}
            >
              Reset Password
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Auth;
