import React, { useState, useEffect } from 'react'
import { Dialog, Button, Select, MenuItem } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { Box, Snackbar, SnackbarContent } from '@mui/material';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
// import firebase from 'firebase-admin';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';

import { db } from '../../../App';
import FrackingIcon from '../../../icons/fracking';
import DrillingIcon from '../../../icons/drilling';

import { useStyles, getFlagDataForSubRegion, compare } from '../utils'
import MainChart from './MainChart';
import MapTable from './MapTable';


const ChartAndMapArea = ({ defaultDate, setDate, setMapData, setEndMapDate, setMapStartDate, mapData, selectedRegion, userData, token, timeseriesChartData, tabValue, setTabValue, isLoading, setSelectedRegion, setClickedRegion, setselectedSubRegions,
    setSelectedOperator, operators, selectedOperator, selectedCounty, setShowCloneDialog, isMapReloading, setUserData, setSelectedCounty, counties, subRegions, selectedSubRegions, isMapClicked, typeChartOrMap, setMapLayerDate, mapLayerDate, currentYear, setCurrentYear, selectedMonth, setSelectedMonth }) => {
    const [showDialog, setShowDialog] = useState(false)
    const [showDialogForShareDoc, setShowDialogForShareDoc] = useState(false)
    const [timeseriesName, setTimeseriesName] = useState('')
    const [dataType, setDataType] = useState('Fracking');
    const [chartType, setChartType] = useState('Line')
    const [axisType, setAxisType] = useState('primary')
    const [color, setColor] = useState('#cccccc')
    const [showError, setShowError] = useState(false)
    const [creatingTimeseries, setCreatingTimeseries] = useState(false)
    const [isEditMode, setIsEditMode] = useState(false)
    const [movingAvg, setMovingAvg] = useState(1)
    const [showTable, setShowTable] = useState(false)
    const [editedTimeSeriesIndex, setEditedTimeSeriesIndex] = useState(null)
    const [isNameTooLong, setIsNameTooLong] = useState(false)
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
    const [ownership, setOwnership] = useState(['PUBLIC', 'PRIVATE']);
    const [tabShareEmail, setTabShareEmail] = useState('');
    const [isSnackBarOpen, setIsSnackBarOpen] = useState(false);
    const [snackBarMsg, setSnackBarMsg] = useState('');
    const styles = useStyles();
    const handleChange = (event) => {
        setDataType(event.target.value);
    };

    const createEditTimeseries = async (userData) => {
        setCreatingTimeseries(true)
        if (timeseriesName === '') {
            setShowError(true)
            setCreatingTimeseries(false)
        } else {
            setShowError(false)
            setTimeseriesName('')
            setShowDialog(false)
            const userRef = doc(db, 'users', userData.uid);
            const docSnap = await getDoc(userRef);
            let chartData = docSnap.data();
            console.log(chartData, "chart Data====>")
            // const userRef = doc(db, 'users', userData.uid);
            const oldTabs = chartData.tabs.filter(tab => tab.id !== tabValue)

            const newTimeSeries = {
                dataType,
                timeseriesName,
                chartType,
                axisType,
                color,
                selectedRegion,
                selectedSubRegions,
                selectedCounty,
                selectedOperator,
                movingAvg
            }
            const oldTimeSeries = chartData.tabs[tabValue].timeseriesArray
            let newTimeSeriesArr = [...oldTimeSeries, newTimeSeries]

            if (isEditMode) {
                newTimeSeriesArr = [...oldTimeSeries.slice(0, editedTimeSeriesIndex), newTimeSeries, ...oldTimeSeries.slice(editedTimeSeriesIndex + 1)]
            }

            const updatedTabs = [...oldTabs, {
                ...chartData.tabs[tabValue], timeseriesArray: newTimeSeriesArr
            }]
            const updatedUserData = {
                ...chartData,
                tabs: updatedTabs.sort(compare)
            }
            setUserData(updatedUserData)
            await updateDoc(userRef, updatedUserData);
            setCreatingTimeseries(false)
        }
    }


    const deleteTimeseries = async (tabValue, index, userData) => {
        const userRef = doc(db, 'users', userData.uid);
        // const otherTabs = userData.tabs.filter(tab => tab.id !== tabValue)
        // const activeTab = userData.tabs.filter(tab => tab.id === tabValue)[0]
        // activeTab.timeseriesArray.splice(index, 1)
        // const updatedActiveTab = {
        //     ...activeTab,
        //     timeseriesArray: activeTab.timeseriesArray
        // }

        // const updatedUserData = {
        //     ...userData,
        //     tabs: [...otherTabs, updatedActiveTab].sort(compare)
        // }
        const tabs = [...userData.tabs];
        const currentTabIndex = userData.tabs.findIndex(tab => tab.id === tabValue);

        tabs[currentTabIndex].timeseriesArray = tabs[currentTabIndex].timeseriesArray.filter((item, i) => i !== index);
        const updatedUserData = { ...userData, tabs }
        setUserData(updatedUserData)
        await updateDoc(userRef, updatedUserData);
    }

    const deleteTab = async () => {
        const otherTabs = userData.tabs.filter((tab, i) => i !== tabValue)
        const userRef = doc(db, 'users', userData.uid);
        const updatedUserData = {
            ...userData,
            tabs: otherTabs.sort(compare).map((tab, i) => ({ ...tab, id: i }))
        }
        if (otherTabs.length > 0 && (tabValue + 1) === userData.tabs.length) {
            setTabValue(tabValue - 1)
        }
        setIsDeleteDialogOpen(false)
        setUserData(updatedUserData)
        await updateDoc(userRef, updatedUserData);
    }

    const editTimeseries = (tabValue, timeseriesIndex) => {
        setShowDialog(true)
        setIsEditMode(true)
        setEditedTimeSeriesIndex(timeseriesIndex)
        const { axisType, chartType, color, dataType, movingAvg, selectedCounty, selectedRegion, selectedSubRegions, timeseriesName, selectedOperator } = userData.tabs[tabValue].timeseriesArray[timeseriesIndex]
        if (axisType) {
            setAxisType(axisType)
        } else {
            setAxisType('primary')
        }
        if (movingAvg) {
            setMovingAvg(movingAvg)
        } else {
            setAxisType(1)
        }
        setChartType(chartType)
        setColor(color)
        setDataType(dataType)
        setSelectedCounty(selectedCounty)
        setSelectedOperator(selectedOperator)
        setSelectedRegion(selectedRegion)
        setClickedRegion({
            Gulf: selectedRegion.includes('Gulf'),
            Midwest: selectedRegion.includes('MidWest'),
            Northeast: selectedRegion.includes('NorthEast'),
            West: selectedRegion.includes('West'),
        });
        setselectedSubRegions(selectedSubRegions)
        setTimeseriesName(timeseriesName)
    }

    const onNameChange = (e) => {
        const newValue = e.target.value

        if (newValue.length < 21) {
            setTimeseriesName(e.target.value)
            setIsNameTooLong(false)
        } else {
            setIsNameTooLong(true)
        }
    }
    const shareChart = async (tabShareEmail) => {
        const authUsers = await axios.get(`/users/${tabShareEmail}`, {
            headers: { Authorization: 'Bearer ' + token },
        });
        if (authUsers.data.user) {
            setTabShareEmail('');
            const { id, requestedTabs } = authUsers?.data?.user;
            const userRef = doc(db, 'users', id);
            const updatedUserData = {
                ...authUsers.data.user,
                requestedTabs: requestedTabs ? [...authUsers.data.user.requestedTabs, userData.tabs.at(tabValue)] : [userData.tabs.at(tabValue)],
            }
            await updateDoc(userRef, updatedUserData).then(() => {
                // alert("Chart has been shared with " + tabShareEmail)
                setIsSnackBarOpen(true)
                setSnackBarMsg("Chart has been shared with " + tabShareEmail)
                setShowDialogForShareDoc(false)
            });
        } else {
            setIsSnackBarOpen(true)
            setSnackBarMsg("No email found in DB")
        }
    }

    // const getPrivateData = () =>{
    //     // mapData.map((ele)=>{
    //     //     console.log(ele,'ele');
    //     //     if(ele.dataType === "drilling"){
    //     //         let x = ele.resultCount.recordset.filter(value => value.ownership === 'PUBLIC');
    //     //         arrDrilling.push(...arrDrilling, x)
    //     //     } if(ele.dataType === "fracking"){
    //     //         let x = ele.resultCount.recordset.filter(value => value.ownership === 'PUBLIC');
    //     //         arrFracking.push(...arrFracking, x)

    //     //     }
    //     //     console.log(arrDrilling,'arrDrilling');
    //     //     console.log(arrFracking,'arrFracking');
    //     // })
    //     console.log(mapData,'data');
    //     let x = mapData.map((ele)=> {
    //         console.log(ele?.resultCount?.recordset  ,'eeee');
    //         let y = ele?.resultCount?.recordset.filter(e => e.ownership === 'PUBLIC')
    //         console.log(y,'yyyyyyyyyy');
    //     });
    //     console.log(x,'xxxxxxxxxxxx');
    // }

    return (
        <div style={{ height: `${showTable ? '100%' : "72vh"}`, paddingBottom: `${showTable ? "3.75rem" : "0px"}` }} className='chartAndMapArea'>
            <Dialog
                open={isDeleteDialogOpen}
                onClose={(e, reason) => {
                    setIsDeleteDialogOpen(false);
                }}
                classes={{ root: 'MuiDialog-root newTab' }}
                disableEscapeKeyDown
            >
                <div className="chartAndMapArea__deleteTab">
                    Are you sure you want to delete this tab
                    <Button style={{
                        color: '#fff',
                        backgroundColor: '#F85348',
                        border: '1px solid #F85348',
                        marginTop: 10,
                        fontSize: 12,
                        width: '100%'
                    }} onClick={deleteTab}>Yes Delete It</Button>
                </div>


            </Dialog>
            <Dialog
                open={showDialog}
                onClose={(e, reason) => {
                    setShowDialog(false);
                }}
                classes={{ root: 'MuiDialog-root newTab' }}
                disableEscapeKeyDown
            >
                <div className="creatNewTimeseries">
                    <div style={{ fontSize: '1.3rem', fontWeight: 600, borderBottom: '1px solid #ccc', paddingBottom: '0.3rem' }}>{isEditMode ? 'Edit Timeseries' : 'Create a New Timeseries'}</div>
                    <input required type="text" placeholder='Name (max 20 Chars)' value={timeseriesName} onChange={onNameChange} /><br />
                    {isNameTooLong && <div style={{ color: 'red', fontSize: 12, marginBottom: 10 }}>timeseries name can't be longer than 20 characters</div>}
                    <div className="creatNewTimeseries__flex">
                        <div className="creatNewTimeseries__left">
                            <div className="creatNewTimeseries__dataTypes">
                                <div>Data Type:</div>
                                {userData?.tabs[tabValue]?.tabType === "map" ?
                                    <Select
                                        labelId="criteria-label"
                                        id="criteria"
                                        label="Age"
                                        onChange={(e) => setDataType(e.target.value)}
                                        value={dataType}
                                    >
                                        <MenuItem value={'Fracking'}>Fracking</MenuItem>
                                        <MenuItem value={'drilling'}>Drilling</MenuItem>
                                    </Select>
                                    :
                                    <Select
                                        labelId="criteria-label"
                                        id="criteria"
                                        label="Age"
                                        onChange={(e) => {
                                            setSelectedRegion([])
                                            setDataType(e.target.value)
                                        }}
                                        value={dataType}
                                    >
                                        <MenuItem value={'Fracking'}>Fracking</MenuItem>
                                        <MenuItem value={'Rigs'}>Rigs</MenuItem>
                                        <MenuItem value={'Monthly Completions'}>Monthly Completions</MenuItem>
                                        <MenuItem value={'DUCs'}>DUCs</MenuItem>
                                        <MenuItem value={'Forecast'}>Forecast (bcf/d)</MenuItem>
                                        <MenuItem value={'Production'}>Production (bcf/d)</MenuItem>
                                        <MenuItem value={'Fracked Feet Per Day'}>Fracked Feet Per Day</MenuItem>
                                        <MenuItem value={'Oil Forecast'}>Oil Forecast</MenuItem>
                                        <MenuItem value={'Oil Production'}>Oil Production</MenuItem>
                                    </Select>
                                }

                            </div>
                            <div className="creatNewTimeseries__color">

                                <div style={{ marginTop: '0.6rem' }}>Color:</div>
                                <input type="color" name="color" id="colorSelector" value={color} onChange={e => setColor(e.target.value)} />
                            </div>

                            {(userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) && <div className="creatNewTimeseries__dataTypes">
                                <div>Chart Type:</div>
                                <Select
                                    labelId="criteria-label"
                                    id="criteria"
                                    label="Age"
                                    onChange={(e) => setChartType(e.target.value)}
                                    value={chartType}
                                >
                                    <MenuItem value={'Line'}>Line</MenuItem>
                                    <MenuItem value={'Bar'}>Bar</MenuItem>
                                </Select>
                            </div>
                            }

                            {(userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) && <div className="creatNewTimeseries__axisTypes">
                                <div>Pick An Axis:</div>
                                <Select
                                    labelId="criteria-label"
                                    id="criteria"
                                    label="Age"
                                    onChange={(e) => setAxisType(e.target.value)}
                                    value={axisType}
                                >
                                    <MenuItem value={'primary'}>Primary Y Axis</MenuItem>
                                    <MenuItem value={'secondary'}>Secondary Y Axis</MenuItem>
                                </Select>
                            </div>
                            }
                            {(userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) &&
                                <div className="creatNewTimeseries__movingAvg">
                                    <div className="creatNewTimeseries__movingAvgTitle">Moving Average:</div>
                                    <input type="number" name="movingAvgNumber" id="" min={1} value={movingAvg} onChange={e => setMovingAvg(parseInt(e.target.value))} />
                                </div>
                            }
                        </div>
                        <div className="creatNewTimeseries__right">
                            {(userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) &&
                                <Autocomplete
                                    disablePortal

                                    id="combo-box-demo"
                                    multiple
                                    options={dataType === "Production" ? ['Gulf', 'West', 'NorthEast', 'MidWest', 'SouthEast'] : ['Gulf', 'West', 'NorthEast', 'MidWest']}
                                    sx={{ width: 250, marginTop: 3 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Region" />
                                    )}
                                    value={selectedRegion}
                                    classes={{
                                        option: styles.option,
                                    }}

                                    onChange={(event, newValue) => {
                                        setSelectedRegion(newValue);
                                        setClickedRegion({
                                            Gulf: newValue.includes('Gulf'),
                                            Midwest: newValue.includes('MidWest'),
                                            Northeast: newValue.includes('NorthEast'),
                                            West: newValue.includes('West'),
                                        });
                                        setselectedSubRegions([]);
                                        setSelectedCounty('');
                                    }}
                                />
                            }

                            {(userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) && (
                                (isMapClicked || selectedRegion.length > 0) && !selectedRegion.includes('SouthEast') ? (
                                    <div style={{ opacity: 1 }}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            options={subRegions}
                                            renderOption={(props, option) => (
                                                <Box
                                                    component="li"
                                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                                    {...props}
                                                >
                                                    <img
                                                        loading="lazy"
                                                        width="20"
                                                        src={`/states/${option
                                                            ? getFlagDataForSubRegion(option)
                                                            : ''
                                                            }.png`}
                                                        alt=""
                                                    />
                                                    {option}
                                                </Box>
                                            )}
                                            multiple
                                            sx={{ width: 250, marginTop: 1 }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Sub Regions" />
                                            )}
                                            value={selectedSubRegions}
                                            onChange={(event, newValue) => {
                                                setselectedSubRegions(newValue);
                                                setSelectedCounty('');
                                            }}

                                        />
                                        {dataType !== 'Production' && dataType !== 'Forecast' && dataType !== 'Fracked Feet Per Day' && dataType !== 'Oil Production' && dataType !== 'Oil Forecast' && (
                                            <Autocomplete
                                                disablePortal
                                                id="combo-box-demo"
                                                options={counties}
                                                sx={{ width: 250, marginTop: 1 }}
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                                        {...props}
                                                    >
                                                        <img
                                                            loading="lazy"
                                                            width="20"
                                                            src={`/states/${option
                                                                ? option.toLowerCase().substr(-3, 2)
                                                                : ''
                                                                }.png`}
                                                            alt=""
                                                        />
                                                        {option}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <div className="countyBox">
                                                        <div className="countyBox__imgBox">
                                                            <img
                                                                src={`/states/${selectedCounty
                                                                    ? selectedCounty
                                                                        .toLowerCase()
                                                                        .substr(-3, 2)
                                                                    : ''
                                                                    }.png`}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <TextField {...params} label="County" />
                                                    </div>
                                                )}
                                                value={selectedCounty}
                                                onChange={(event, newValue) => {
                                                    setSelectedCounty(newValue);
                                                }}

                                            />
                                        )}
                                    </div>

                                ) : <span style={{ fontSize: 12 }}>{selectedRegion.includes('SouthEast') ? '' : 'Sub-regions & counties selector will show up after you select regions'}</span>)}

                            {dataType !== 'Production' && dataType !== 'Forecast' && dataType !== 'Fracked Feet Per Day'  && dataType !== 'Oil Production' && dataType !== 'Oil Forecast' && (
                                <Autocomplete
                                    disablePortal
                                    id="combo-box-demo"
                                    options={operators}
                                    sx={{ width: 250, marginTop: 1 }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Operator Name" />
                                    )}
                                    value={selectedOperator}
                                    onChange={(event, newValue) => {
                                        setSelectedOperator(newValue);
                                    }}
                                />
                            )}
                        </div> </div>
                    <div className="creatNewTimeseries__submitBtn">
                        {showError && <div style={{ color: 'red', fontSize: 12, marginBottom: 10 }}>please give a name to your timeseries</div>}
                        <Button style={{
                            color: '#fff',
                            backgroundColor: '#23263C',
                            border: '1px solid #23263C',
                        }} variant='contained' type='submit' onClick={() => createEditTimeseries(userData)}>
                            {isEditMode ? creatingTimeseries ? <CircularProgress size={20} /> : 'Save Timeseries' : creatingTimeseries ? <CircularProgress size={20} /> : userData?.tabs?.[tabValue]?.tabType === "map" ? 'Create Layer' : 'Create Timeseries'}
                        </Button>
                    </div>

                </div>
            </Dialog>

            {/* modal for share doc input */}
            <Dialog
                open={showDialogForShareDoc}
                onClose={(e, reason) => {
                    setShowDialogForShareDoc(false);
                }}
                classes={{ root: 'MuiDialog-root newTab' }}
                disableEscapeKeyDown
            >
                <div style={{ padding: '20px' }}>
                    <p style={{ margin: '5px' }}>Send chart to.</p>
                    <TextField size='small' fullWidth label="Please enter the email" variant="outlined" onChange={(e) => setTabShareEmail(e.target.value)} />
                    <Button style={{
                        color: '#fff',
                        backgroundColor: '#38e4ae',
                        border: '1px solid #afafaf',
                        marginTop: 10,
                        fontSize: 12,
                        width: '100%'
                    }} onClick={() => shareChart(tabShareEmail)}>Share chart</Button>
                </div>
            </Dialog>

            <Snackbar
                open={isSnackBarOpen}
                autoHideDuration={6000}
                onClose={() => setIsSnackBarOpen(false)}
                message={snackBarMsg}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
                <SnackbarContent
                    message={<span id="client-snackbar">{snackBarMsg && snackBarMsg}</span>}
                />
            </Snackbar>

            {userData.tabs && userData.tabs.length > 0 && (
                <div className="chartAndMapArea__content">
                    <div className="chartAndMapArea__left">
                        <div style={{ width: "100%" }}>
                            <Button
                                style={{
                                    color: '#fff',
                                    backgroundColor: '#F85348',
                                    border: '1px solid #F85348',
                                    marginBottom: 5,
                                    fontSize: 12,
                                    width: '100%'
                                }}
                                onClick={() => setIsDeleteDialogOpen(true)}>Delete This Tab</Button>
                            {userData?.tabs?.[tabValue]?.tabType !== "table" && (
                                <>
                                    <Button
                                        style={{
                                            color: '#000',
                                            backgroundColor: '#38e4ae',
                                            border: '1px solid #000',
                                            marginBottom: 5,
                                            fontSize: 12,
                                            width: '100%'
                                        }}
                                        onClick={() => setShowCloneDialog(true)}>Clone This Tab</Button>
                                    <Button style={{
                                        color: '#000',
                                        backgroundColor: '#4cc9ff',
                                        border: '1px solid #23263C',
                                        marginBottom: 15
                                    }} fullWidth variant="contained" onClick={() => {
                                        setShowDialogForShareDoc(true)
                                    }}>Share this tab</Button>

                                    <Button style={{
                                        color: '#23263C',
                                        backgroundColor: '#fff',
                                        border: '1px solid #fff',
                                        fontSize: '0.8rem',
                                        width: '100%',
                                        marginBottom: 10
                                    }} variant="contained" onClick={() => {
                                        setShowDialog(true)
                                        setTimeseriesName('')
                                        setIsEditMode(false)
                                    }}>{(userData?.tabs[tabValue]?.tabType === "chart" || !userData?.tabs[tabValue]?.tabType) ? "Create a New Timeseries" : "Create a New Layer"}</Button>


                                </>
                            )}


                            <div className="chartAndMapArea__timeseries">
                                {userData?.tabs[tabValue]?.timeseriesArray && userData?.tabs[tabValue]?.timeseriesArray?.length > 0 && userData?.tabs[tabValue]?.timeseriesArray?.map(({ timeseriesName, color, dataType }, i) => (
                                    <div key={i} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginRight: 10 }}>
                                            <div className="chartAndMapArea__timeseries--name">{timeseriesName}</div>
                                            <div className="chartAndMapArea__timeseries--color" style={{ width: 15, height: 15, background: color, borderRadius: 4, marginLeft: 5, marginTop: 3 }}></div>

                                        </div>

                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div onClick={() => editTimeseries(tabValue, i)} className="chartAndMapArea__timeseries--delete"><EditIcon
                                                style={{
                                                    color: '#F5AA57',
                                                    cursor: 'pointer'
                                                }}
                                            /></div>
                                            <div onClick={() => deleteTimeseries(tabValue, i, userData)} className="chartAndMapArea__timeseries--delete"><DeleteIcon
                                                style={{
                                                    color: '#F5AA57',
                                                    cursor: 'pointer'
                                                }}
                                            /></div>
                                        </div>

                                    </div>
                                ))}
                                {userData.tabs[tabValue].timeseriesArray && userData?.tabs?.[tabValue]?.tabType !== "map" && userData.tabs[tabValue].timeseriesArray.length > 0 && 
                                <>
                                <div style={{ marginTop: 20, color: '#ccc', fontSize: 13 }}>NG Production and Forecast data is in BCF/D</div>
                                <div style={{ marginTop: 2, color: '#ccc', fontSize: 13 }}> Oil Production and Forecast data is in KB/D </div>
                                </>
                                }
                            </div>
                        </div>

                        {userData?.tabs?.[tabValue]?.tabType === "map" && userData?.tabs?.[tabValue]?.timeseriesArray?.length > 0 &&
                            <div style={{ width: "100%" }}>
                                <h4 style={{ color: "#fff", marginBottom: "8px" }}>Index :</h4>
                                <div style={{ display: "flex", gap: "10px" }}>
                                    <div style={{ display: "flex", gap: "10px", cursor: 'pointer' }} onClick={() => {
                                        let x = ownership.includes('PUBLIC');
                                        let i = ownership.indexOf('PUBLIC')
                                        if (!x) {
                                            setOwnership([...ownership, 'PUBLIC'])
                                        } else {
                                            // setOwnership(ownership.splice(i,1))
                                            let filterArr = ownership.filter((r) => r !== "PUBLIC")
                                            setOwnership(filterArr)
                                        }
                                    }}>
                                        <FrackingIcon fillColor="#fff" fill="#b8b5b0" />
                                        <h4 style={{ margin: "0px", color: "#fff", fontWeight: 400 }} className={ownership.includes("PUBLIC") ? '' : 'lineTrough'}>Public</h4>
                                    </div>
                                    <div style={{ display: "flex", gap: "10px", cursor: 'pointer' }} onClick={() => {
                                        let x = ownership.includes('PRIVATE');
                                        let i = ownership.indexOf('PRIVATE')
                                        if (!x) {
                                            setOwnership([...ownership, 'PRIVATE'])
                                        } else {
                                            let filterArr = ownership.filter((r) => r !== "PRIVATE")
                                            setOwnership(filterArr)
                                        }
                                    }}>
                                        <FrackingIcon fillColor="#b8b5b0" fill="#fff" />
                                        <h4 style={{ margin: "0px", color: "#fff", fontWeight: 400 }} className={ownership.includes("PRIVATE") ? '' : 'lineTrough'}>Private</h4>
                                    </div>
                                </div>
                            </div>
                        }

                        {userData?.tabs?.[tabValue]?.tabType === "table" && <MapTable tabValue={tabValue} userData={userData} token={token} iconId={userData?.tabs?.[tabValue]?.name} />}
                    </div>

                    <div className="chartAndMapArea__right">
                        {isMapReloading ? <CircularProgress size={20} color="warning" style={{ marginLeft: '30%' }} /> : (
                            userData.tabs[tabValue].timeseriesArray && (
                                <MainChart
                                    timeseriesChartData={userData.tabs[tabValue].timeseriesArray.length > 0 ? timeseriesChartData : []}
                                    setMapStartDate={setMapStartDate}
                                    setEndMapDate={setEndMapDate}
                                    userData={userData}
                                    setUserData={setUserData}
                                    tabValue={tabValue}
                                    typeChartOrMap={typeChartOrMap}
                                    mapData={userData.tabs[tabValue].timeseriesArray.length > 0 ? mapData : []}
                                    token={token}
                                    ownership={ownership}
                                    setMapLayerDate={setMapLayerDate}
                                    mapLayerDate={mapLayerDate}
                                    setShowTable={setShowTable}
                                    showTable={showTable}
                                    setDate={setDate}
                                    currentYear={currentYear}
                                    setCurrentYear={setCurrentYear}
                                    selectedMonth={selectedMonth}
                                    setSelectedMonth={setSelectedMonth}
                                    defaultDate={defaultDate}
                                />
                            ))}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ChartAndMapArea