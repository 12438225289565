import * as React from "react"

const FrackingIcon = ({fillColor, fill}) =>{ 

    return (
        <svg xmlns="http://www.w3.org/2000/svg"  width="80" height="100" viewBox="0 0 80 80">
            <g id="Group_119" data-name="Group 119" transform="translate(-1352 -1001)">
                <circle id="Ellipse_3" data-name="Ellipse 3" cx="40" cy="40" r="40" transform="translate(1352 1001)" fill={fillColor} />
                <g id="frack" transform="translate(1364 2167.3)">
                    <path id="Path_45" data-name="Path 45" d="M24.156-1138.016c-2.027,8.1-2.075,8.281-2.149,8.253-.038-.014-3.18-1.359-6.974-2.987s-6.936-2.974-6.978-2.991c-.073-.028-.077-.024-.042.066.059.147,5.793,15.437,5.9,15.737l.1.269-6.006,2c-3.305,1.1-6.01,2.013-6.01,2.023,0,.045,13.284,9.333,13.309,9.3.108-.119,1.936-2.767,1.939-2.806s-1.646-1.205-3.76-2.69c-2.072-1.453-3.767-2.656-3.77-2.676s1.985-.7,4.413-1.506c3.445-1.15,4.41-1.481,4.4-1.523s-1-2.694-2.208-5.919-2.194-5.867-2.191-5.87,2.327.989,5.161,2.2,5.161,2.2,5.171,2.194.657-2.565,1.436-5.681l1.419-5.667,2.82,5.643c1.555,3.1,2.827,5.646,2.834,5.653s2.512-1.24,5.573-2.771,5.57-2.774,5.577-2.767-1.272,2.914-2.848,6.457-2.869,6.464-2.876,6.492,1.251.468,4.661,1.607l4.675,1.558-3.2,2.722c-1.761,1.5-3.208,2.736-3.218,2.753-.024.035,2.184,2.631,2.24,2.631s11.1-9.4,11.122-9.451c.007-.014-2.593-.895-5.772-1.957l-5.783-1.925.063-.147c.035-.08,1.869-4.2,4.074-9.161s4.015-9.036,4.025-9.06-3.753,1.841-8.361,4.144l-8.382,4.193-4.161-8.319-4.161-8.316Z" fill={fill} />
                    <path id="Path_46" data-name="Path 46" d="M471.794-598.873c-.007.073-1.541,10.122-1.562,10.21s-.3-.2-2.624-2.516c-1.433-1.429-2.6-2.568-2.586-2.53s.776,1.971,1.7,4.3,1.695,4.256,1.7,4.287c.017.059-.164.063-3.452.066H461.5l3.47,1.737c1.9.954,3.466,1.747,3.466,1.758s-1.167.8-2.589,1.747l-2.593,1.726,4.794.01c2.635,0,6.946,0,9.581,0l4.794-.01-2.593-1.726c-1.422-.95-2.589-1.737-2.589-1.747s1.558-.8,3.459-1.751l3.459-1.73-3.473-.017-3.473-.017,1.726-4.315c.95-2.373,1.723-4.326,1.719-4.34s-1.569,1.146-3.477,2.575l-3.466,2.6-.035-.182c-.021-.1-.447-2.414-.947-5.143s-.915-4.979-.922-5S471.794-598.9,471.794-598.873Z" transform="translate(-443.445 -528.279)" fill={fill} />
                </g>
            </g>
        </svg>
    )
}


export default FrackingIcon;