import React, {useState} from 'react'
import { TextField } from '@mui/material';
import { verifyPasswordResetCode, confirmPasswordReset, getAuth } from "firebase/auth";
import LoadingButton from '@mui/lab/LoadingButton';
import { Navigate} from 'react-router-dom';
const ResetPassword = ({setIsSnackBarOpen, setSnackBarMsg}) => {
    const [newPassword, setNewPassword] = useState('')
    const [isLoading, setIsLoading] = useState(false);

   const resetPassword = () => {
        const actionCode = window.location.href.split('&oobCode=')[1].split('&apiKey=')[0]
        let auth = getAuth();
        setIsLoading(true)
        verifyPasswordResetCode(auth, actionCode).then((email) => {
            // Save the new password.
            confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
            // Password reset has been confirmed and new password updated.
            setIsLoading(false)
            setIsSnackBarOpen(true)
            setSnackBarMsg('Password Changed Successfully')
            window.location.href = '/login'
            }).catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            setIsLoading(false)
            setIsSnackBarOpen(true)
            setSnackBarMsg('Error changing password')
           
            });

           
        }).catch((error) => {
            // Invalid or expired action code. Ask user to try to reset the password
            // again.
            setIsLoading(false)
            setIsSnackBarOpen(true)
            setSnackBarMsg('Error changing password')

        });
   }
      
 
  return (
    <div className="auth">
         <div className="auth__mainHeading">Enter Your New Password</div>
         <div className="auth__formBox">
          <form className="auth__inputs auth__signUp">
            <div className="auth__email">
              <TextField
                id="outlined-basic"
                label="Password"
                variant="standard"
                type="password"
                name="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <LoadingButton
              variant="outlined"
              className="button"
              onClick={resetPassword}
              loading={isLoading}
              type="submit"
              style={{
                width: 250,
              }}
            >
             Reset Password
            </LoadingButton>
          </form>
        </div>
    </div>
  )
}

export default ResetPassword