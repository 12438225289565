import React, { useState, useEffect } from 'react';
import { geoEqualEarth, geoPath } from 'd3-geo';
import { feature } from 'topojson-client';

import './geoml.scss';

const projection = geoEqualEarth().scale(900).translate([1550, 850]);

const WorldMap = ({ clickedRegion, setClickedRegion, disabled}) => {
  const [geographies, setGeographies] = useState([]);

  useEffect(() => {
    fetch('/usa-regions.json').then((response) => {
      if (response.status !== 200) {
        console.log(`There was a problem: ${response.status}`);
        return;
      }
      response.json().then((worlddata) => {
        setGeographies(feature(worlddata, worlddata.objects.regions).features);
      });
    });
  }, []);

  const handleRegionClick = (countryIndex) => {
    const id = geographies[countryIndex].id;
    setClickedRegion({
      ...clickedRegion,
      [id]: !clickedRegion[id],
    });
  };

  return (
    <div className="d3map" style={{ pointerEvents: disabled ? 'none' : 'unset'}}>
      <svg width={300} height={200} viewBox="0 0 800 450">
        <g className="regions">
          {geographies.map((d, i) => {
            return (
              <path
                d={geoPath().projection(projection)(d)}
                className="region"
                fill={d.properties.color}
                fillOpacity={clickedRegion[d.id] ? 1 : 0.3}
                stroke="#FFFFFF"
                strokeWidth={0.5}
                onClick={() => handleRegionClick(i)}
                style={{ cursor: 'pointer' }}
                key={`key-${i}`}
              />
            );
          })}
        </g>
      </svg>

      <div className="d3map__label d3map__label--1" onClick={() => handleRegionClick(3)}>West</div>
      <div className="d3map__label d3map__label--2" onClick={() => handleRegionClick(0)}>Mid West</div>
      <div className="d3map__label d3map__label--3" onClick={() => handleRegionClick(2)}>Gulf</div>
      <div className="d3map__label d3map__label--4" onClick={() => handleRegionClick(1)}>North East</div>
    </div>
  );
};

export default WorldMap;
