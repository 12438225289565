import React, { useState } from 'react';
import { TextField, Button, Snackbar } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail 
  } from 'firebase/auth';

const SingupForm = ({ setErrMessage }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const auth = getAuth();
  const createNewUser = () => {
    setIsLoading(true);
    setErrMessage('');
    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {          
        localStorage.setItem("firstName", firstName)
        localStorage.setItem("lastName", lastName)
      })
      .catch((error) => {
        setIsLoading(false);
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        setErrMessage(errorMessage);
      });
  };

  return (
    <div className="auth__formBox">
      <div className="auth__header">New To Hyperion? Sign Up</div>
      <form className="auth__inputs auth__signUp">
        <div className="auth__email">
          <TextField
            id="outlined-basic"
            label="Email"
            variant="standard"
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="auth__name">
          <TextField
            id="outlined-basic"
            label="First Name"
            variant="standard"
            type="text"
            name="name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>

        <div className="auth__lastName">
          <TextField
            id="outlined-basic"
            label="Last Name"
            variant="standard"
            type="text"
            name="name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>

        <div className="auth__password">
          <TextField
            id="password"
            label="Password"
            variant="standard"
            type="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <LoadingButton
          variant="outlined"
          className="button"
          onClick={createNewUser}
          loading={isLoading}
          type="submit"
        >
          Sign Up
        </LoadingButton>
      </form>
    </div>
  );
};

export default SingupForm;
