import React, { useMemo, useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useTable } from "react-table";
import axios from 'axios';

import './DataArea.scss';
import { getColumns } from '../utils'
import DownloadMapTableXLS from "./DownloadMapTableXLS";


export default function MapTable({ iconId, token, userData, tabValue }) {
    const [tableData, setTableData] = useState(null)
    const [isTableLoading, setIsTableLoading] = useState(false)
    const [mapTableData, setMapTableData] = useState(null);

    useEffect(() => {
        getTableData()
    }, [iconId])

    const getTableData = async () => {
        setTableData(null)
        setIsTableLoading(true)
        const tableDataRes = await axios.get('/map/table', {
            headers: { Authorization: 'Bearer ' + token },
            params: {
                wellId: iconId
            },
        });
        setIsTableLoading(false)
        setTableData(Object.entries(tableDataRes.data))
        setData(tableDataRes.data)
    }

    const setData = (data) =>{
        setMapTableData([data])
    }
    return (
        <>
        {mapTableData?.length > 0 && <DownloadMapTableXLS tabValue={tabValue} userData={userData} datasets={mapTableData} />}
        {isTableLoading && <CircularProgress size={20} />}
            {tableData &&
                <div className="table__container">
                    <div>
                    </div>
                    {
                        tableData?.map(([key, value]) => {
                            return (
                                value.length > 0 &&
                                <Table key={key} tableData={value} column={getColumns(convertTableHeading(key), value)} />
                            );
                        })
                    }
                </div>}

        </>
    )
}

function Table({ tableData, column }) {
    const data = tableData
    const columns = useMemo(() => column, []);

    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({ columns, data });

    return (
        <>
            <div>
                <table {...getTableProps()} >
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    )
}


const convertTableHeading = (oldName) => {
    if(oldName === 'wells') {
      return 'Wells'
    }
  
    if(oldName === 'drills') {
      return 'Drills'
    }
  
    if(oldName === 'completionJobs') {
      return 'Completion Jobs'
    }
  }