import React, {useRef} from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Line } from 'react-chartjs-2';
import { CircularProgress } from '@mui/material';
import { Button } from '@mui/material';
import { allDates, leapYearDates } from './allDates';

import './geoml.scss';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  zoomPlugin,
  annotationPlugin
);

const FrackingChart = ({ datesAndLables, isLoading, chartRef, chartType }) => {
  const { dates2022, dates2021,dates2020, dates2019, dates2018, dates2017, dates2016 } = datesAndLables;
  const isMobileScreen = window.innerWidth < 600;
  const data = {
    labels: allDates,
    datasets: [
      {
        label: '2021',
        data: dates2021,
        borderColor: 'rgb(248,83,72)',
        backgroundColor: 'rgba(248,83,72,0.5)',
       pointRadius: 2,
      },
      {
        label: '2022',
        data: dates2022,
        borderColor: 'rgb(36,160,202)',
        backgroundColor: 'rgba(36,160,202,0.5)',
       pointRadius: 2,
      },
    ],
  };

  const ducData = {
    labels: leapYearDates,
    datasets: [
      {
        label: '2016',
        data: dates2016,
        borderColor: 'rgb(153, 0, 255)',
        backgroundColor: 'rgba(153, 0, 255, 0.8)',
        pointRadius: 2,
        hidden: true,
      },
      {
        label: '2017',
        data: dates2017,
        borderColor: 'rgb(255, 102, 255)',
        backgroundColor: 'rgba(255, 102, 255, 0.5)',
       pointRadius: 2,
       hidden: true,
       spanGaps: true
      },
      {
        label: '2018',
        data: dates2018,
        borderColor: 'rgb(245,170,87)',
        backgroundColor: 'rgba(245,170,87,0.5)',
       pointRadius: 2,
       hidden: true,
       spanGaps: true
      },
      {
        label: '2019',
        data: dates2019,
        borderColor: 'rgb(0, 255, 0)',
        backgroundColor: 'rgba(0, 255, 0, 0.3)',
       pointRadius: 2,
       hidden: true,
       spanGaps: true
      },
      {
        label: '2020',
        data: dates2020,
        borderColor: 'rgb(60,61,107)',
        backgroundColor: 'rgba(60,61,107,0.5)',
       pointRadius: 2,
      },
      {
        label: '2021',
        data: dates2021,
        borderColor: 'rgb(248,83,72)',
        backgroundColor: 'rgba(248,83,72,0.5)',
       pointRadius: 2,
       spanGaps: true
      },
      {
        label: '2022',
        data: dates2022,
        borderColor: 'rgb(36,160,202)',
        backgroundColor: 'rgba(36,160,202,0.5)',
       pointRadius: 2,
       spanGaps: true
      },
    ],
  };

  
ChartJS.register(
  {
    id: 'uniqueid5', //typescript crashes without id
    afterDraw: function (chart, easing) {
      if (chart.tooltip._active && chart.tooltip._active.length) {
        const activePoint = chart.tooltip._active[0];
        const ctx = chart.ctx;
        const x = activePoint.element.x;
        const topY = chart.scales.y.top;
        const bottomY = chart.scales.y.bottom;
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(x, topY);
        ctx.lineTo(x, bottomY);
        ctx.lineWidth = 2;
        ctx.strokeStyle = '#3C3D6B';
        ctx.stroke();
        ctx.restore();
      }
    }
  }
);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: true,
    animationSteps:1,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    hover: {
      mode: 'index',
    intersect: false
      },
    plugins: {
      pan: {
        enabled: true,
        mode: 'x',
      },
      // zoom: {
      //   zoom: {
      //     wheel: {
      //       enabled: true,
      //     },
      //     pinch: {
      //       enabled: true,
      //     },
      //     mode: 'xy',
      //   },
      // },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
      
      legend: {
        position: 'top',
        align: 'center',
        labels: {
          boxWidth: isMobileScreen ? 30 : 40,
          boxHeight: isMobileScreen ? 15 : 20,
          font: {
            size: isMobileScreen ? 11 : 14
          },
          
        }
      },
      title: {
        display: true,
        text: `${chartType} ${chartType === 'DUCs' ? '' : 'Data for 2021/22'}`,
        font: {
          size: isMobileScreen ? 14 : 18
        }
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 12,
          font: {
            size: isMobileScreen ? 11 : 16,
            weight: isMobileScreen ? '' :'bold'
          },
          callback: function(value, index) {
            return this.getLabelForValue(index).substr(3);
          }
        },
      },
      y: {
        ticks: {
          precision: 0,
          font: {
            size: isMobileScreen ? 11 : 16,
            weight: isMobileScreen ? '' :'bold'
          }
        },
        beginAtZero: true,
        display: true,
        title: {
          display: true,
          text: `No of ${chartType} ${chartType === 'Fracking' ? 'Crews' : ''}`,
          color: '#333',
          font: {
            // family: 'Times',
            size: isMobileScreen ? 14 : 20,
            style: isMobileScreen ? '' : 'normal',
            lineHeight: 1.2,
          },
          padding: { top: 30, left: 0, right: 20, bottom: 0 },
        },
      },
    },
    elements: {
      point: {
        radius: window.innerWidth < 600 ? 0 : 4,
      },
    },
  };

  return (
    <div className="frackingData">
      {isLoading ? (
        <div className="loadingBox">
          <CircularProgress size={80} />
        </div>
      ) : (
        <Line ref={chartRef} options={options} data={chartType === 'DUCs' ? ducData : data} />
      )}
    </div>
  );
};

export default FrackingChart;
